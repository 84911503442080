.react-tel-input .form-control {
    width: 100% !important;
    height: 34px !important;
    padding-left: 45px !important;
    z-index: 4;
    background: none !important;
}

.phone-label {
    position: absolute;
    left: 66px;
    bottom: 1px;
    color: #0000008a !important;
}

.country>* {
    color: #000 !important;
    font-weight: bold;
}

.react-tel-input .flag-dropdown {
    z-index: 4;
    background-color: #eee;
    border-radius:8;
}

.react-tel-input input {
    border-top: 1px solid #ced4da;
    border-left: 0 !important;
    border-right: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}

.phone-label.transform {
    opacity: 1;
    left: 0;
    transform: scale(.9) translateY(-155%) translateX(-5px);
    /* color: #606162 !important; */
}

.flag-dropdown {
    border-top:  1px solid #ced4da;;
    border-right:0 !important;
    border-left: 1px solid #ced4da;
    border-bottom: 1px solid #ced4da;
}

.react-tel-input .flag-dropdown:hover .selected-flag {
    cursor: pointer;
    background-color: #eee !important;
}

.form-control input:focus {
    background-color: #fff !important;
}
input.input-error:focus{
    border: 1px solid #EC5F60 !important;
}

.phone-control .react-tel-input input.form-control:focus{
    background-color: #fff !important;
}
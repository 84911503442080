#demo-multiple-checkbox~input.MuiSelect-nativeInput {
    display: none !important;
}


ul[aria-labelledby="demo-multiple-checkbox-label"] {
    padding: 0px !important;
}

ul[aria-labelledby="demo-multiple-checkbox-label"] li {
    padding: 0px !important;
    display: flex !important;
}

ul[aria-labelledby="demo-multiple-checkbox-label"] li span {
    margin: 3px !important;
}

.form-group div .MuiFormControl-root label[id="demo-multiple-checkbox-label"] {
    line-height: 15px !important;
    margin-left: -13px !important;
}

.form-group div .MuiFormControl-root label[id="demo-multiple-checkbox-label"].MuiInputLabel-shrink {
    line-height: 15px !important;
    margin-left: 0px !important;
}

.multi-select-search div.MuiAutocomplete-root div.MuiFormControl-root label {
    margin-left: 0px !important;
}

.multi-select-search div.MuiAutocomplete-root div.MuiFormControl-root input {
    height: 19px !important;
}


li.li-classname {
    padding : 0px !important;
    margin: 0px !important;
    line-height: 0px  !important;
}

.max-height-35 {
    max-height: 35px !important;
}

.max-width-800 {
    max-width: 800px !important;
}

.max-width-350 {
    max-width: 350px !important;
}

.demo-multiple-checkbox-label{
    transform: translate(4, 24px) scale(1) !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
    font-size: 21px !important;
    position: absolute !important;
    right: 0px !important;
}


.Mui-focused>.MuiSelect-select{
    background-color: #eee !important;
}

.multi-select-search-li{
    cursor: pointer;
}

.multi-select-search-li :hover {
    background-color: #dbdfe2a8;
    border-radius: 3px;
}

.multi-select-search-li-selected {
    background-color: rgba(158,207,250,0.3);
    border-radius: 3px;
    cursor: pointer;
}


.input-error fieldset {
    border-color: #EC5F60 !important;
}

.MuiSvgIcon-root.MuiSelect-icon{
color:#fff
}


.MuiListItem-root.Mui-selected {
background-color: rgba(158,207,250,0.3);
}
.margin-top26 {
    margin-top: 26px;
}


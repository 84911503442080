.row-selected {
  background-color: #d0edf7; 
}

.btn-select-height35 {
  height: 31px;
  min-width: 35px !important;
  max-width: 35px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  font-weight: bold;
}

.npi_error{
  padding: 10px;
  background-color: #fc7276;
  text-align: center;
  border-radius: 5px;
  color:#fff;
  font-size: 13px;
  font-family: 'Lato';
}


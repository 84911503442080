.buttonContainer {
    position: relative;
}

.button {
    align-items: stretch;
}

.popup {
    margin-top: -30px;
    margin-left: 250px;
    position: absolute;
    z-index: 9;
    background-color: black;
    color: white;
    border-radius: 10px;
    width: 200px;
}

.child {
    display: inline-block;
}

.unorderMenu {
    list-style-type: none;
}

.menuItem {
    margin: 5px;
    padding: 2px;
    font-size: medium;
}

.menuItem:hover {
    cursor: pointer;
}
.hidingView {
    display: none;
}

.hidingView.show {
    display: contents;
    width: 100%;
    height: 100%;
    background: #fff ;
    transition: width 2s;
    transition-timing-function: linear;
    transition-delay: 1s;
    transition-duration: 2s;
}

.tableInput input{
    border: 1px solid #ced4da !important;
    border-radius: 4px;
    padding-left: 5px !important;
    height: 32px !important;
    width: 100% !important;
    text-align: right;
    padding-right: 1.5px !important;
}

.MuiInputBase-input.table-input-era-posting {
    height: 2.4em !important;
    width: 100% !important;
    text-align: right;
    padding-right: 1.5px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table-input-era-posting.rbt input {
    background: transparent !important;
}

.tableInput input:focus{
    border: 1px solid #ced4da !important;
}

.tableInput_error input{
    border: 1px solid #ee0d0d !important;
    border-radius: 4px;
    padding-left: 5px !important;
    height: 32px !important;
    width: 100% !important;
    text-align: right;
    padding-right: 1.5px !important;
}

.tableInput_error input:focus{
    border: 1px solid #ee0d0d !important;
}

.rbt-menu.dropdown-menu.show{
    max-height: 200px !important;
        z-index: 10 !important;
}

.editButton{
    height: 35px;
}

.box {
    height: 100% !important;
} 

 /*.justify_space_between {
    display: flex !important;
    justify-content: space-between !important;
} */

 .head_image {
    width: 160px !important;
} 

 .border {
    border: 1px solid #ddd !important;
} 

 .textCenter {
    text-align: center !important;
} 

 /* ul {
    list-style-type: none !important;
}  */

.padding_bottom30 {
    padding-bottom: 30px !important;
}

 small.bottom {
    position: relative;
    bottom: 0 !important;
    padding-left: 30px !important;
} 

.liSpanBold {
    font-weight: 800 !important;
} 

.headerReceipts {
    background: #ffffff;
    min-height: 68px;
    padding: 0px 0;
    overflow: visible;
    position: fixed;
    top: 0;
    right: 0;
    -webkit-transition: .45s;
    transition: .45s;
    border-bottom: 1px solid #E6E6E6;
    z-index: 999;
    left: 0px;
    align-items: center !important;
}

.fullContent {
    background: #ffffff; font-family: "Roboto", Arial, Helvetica, sans-serif;
}

.margin-20-no-left{
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 20px;
}

.receipts-logo .company-logo img {
    width: 110px;
    height: 85px;
}

.form-group_none .form-group{
    margin-bottom: 0%;
}

table#overflow_auto{
    table-layout: auto !important;
}

/* table#overflow_auto thead tr th{
    text-align: center !important;
} */

table#overflow_auto tbody tr td{
    border: 1px solid #f0f0f0;
}
#claimDetailBody{
    max-height: 700px;
    height: 40vh;
}
#claimDetailModal{
    max-width: 85vw;
    margin-top: 41vh;
    margin-left: 8vw;
}
#columnModalBody{
    max-height: 700px;
}
#columnModal{
    max-width: none;
}

.closeIconRemittanceCode{
    position: absolute;
    top: 15px;
    right: 7px;
    cursor: pointer;
    z-index: 100;
}

.closeIconRemittanceCode i.fa-times{
    color: #a9a9a9;
}
.closeIconRemittanceCode:hover i.fa-times{
    color: #EC5F60;
}
.filter-icons:hover {
    color: #1074B9;
}

.procedureEditedTableRow {
    background-color: #FFF6DC !important;
}
.procedureEditedTableRow:hover {
    background-color: #FFF2CA !important;
}

.eraItemReviewed {
    background-color: rgba(36, 167, 224, 0.10) !important;
}

.link-text-color {
    color: var(--primary-p-2, #1479BB);
    cursor: pointer;
}

.link-text-color :hover {
    color: var(--primary-p-2, #1479BB);
    cursor: pointer;
}

.popover-content {
  font-family: inherit;
}

.custom-popover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.custom-popover-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 7px 0 0 0 ;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #ced4da !important;
  background-color:#fff;
  width: 140px;
}
.paymentTextHeading{
    color: #212121;
    font-weight: bold;
}

.applied-status-label,
.paymentTextGeneral{
    color: #424242;
}

.applied-status {
    margin-left: 144px;
    color: #212121;
    font-weight: bold;
}

.greyText{
    color:#757575;
}

.applied{
    margin-left: 128px;
    color: #212121;
    font-weight: bold;
}

.unapplied{
    margin-left: 118px;
    color: #212121;
    font-weight: bold;
}

.adjustments{
    margin-left: 15px;
    color: #212121;
    font-weight: bold;
}

.ein_eft{
    margin-left:60px;
      color: #212121;
    font-weight: bold;
}

.production_date{
    margin-left: 40px;
    color: #212121;
    font-weight: bold;
}

.checkDate{
    margin-left: 65px;
    color: #212121;
    font-weight: bold;
}

.checkAmount{
    margin-left: 50px;
    color: #212121;
    font-weight: bold;
}
.uitextAlign{
    margin-left: 300px;
    font-family:"Lato";
   
}
.uiPaymentfontsize{
    font-weight: 800 !important;
    font-family:"Lato";
}
.textCenterAlignPaymentRecipt{
    text-align: center !important;
    font-family:"Lato";
    font-weight: bold;
}
.uinoteText{
    font-weight: 800 !important;
    margin-left: 300px;
}
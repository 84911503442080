.bordering {
    border: 0px solid #fff;
   background: #fff;
   margin-right:20px
}

.bordering-no-margin {
   border: 0px solid #fff;
   background: #fff;
   margin-right:0px
}

.bordering_without_radius {
    border: 0px solid #fff;
   background: #fff;
   margin-right:20px;
   border-radius: 0px !important;
}

.payment-box {
   border: 0px solid #fff;
   background: #fff;
   border-radius: 8px !important;
   margin-right:20px
}

.bordering-radius {
    border: 1px solid #dddd;
    padding: 5px;
    border-radius: 15px;
}

.s-dropdown-menu {
    min-width: 15rem !important;
    width: 100%;
    padding-top: 10px;
}

.fa-caret-down {
    padding-left: 3px;
    color: #606162;
}

.diagnosisLabel input:focus+label,
.diagnosisLabel input:not(:placeholder-shown)+label {
    transform: scale(0.8) translateY(-137%) translateX(0px) !important;
}

.input-responsible-cliams {
    margin-left: 0px !important;
}

.input-procedure {
    max-width: 110px !important;
    margin-left: 10px;
}

.input-procedure-text {
    width: 100%;
    max-width: 200px;
    margin-left: 10px;
}

.input-procedure-text_dx {
    width: 100%;
    max-width: 139px;
    margin-left: 10px;
}
.input-procedure-text_Fee {
    width: 100%;
    max-width: 140px;
    margin-left: 10px;
}

.input-procedure-text_Charges{
     width: 100%;
    max-width: 140px;
    margin-left: 10px;
}

.input-procedure-text_expand1
{
    width: 100%;
    max-width: 141px;
    margin-left: 10px; 
}
.input-procedure-text_anthesia
{
    width: 100%;
    min-width: 200px;
    max-width: 200px;
    margin-left: 10px;
}
.input-procedure-text60 {
    width: 100%;
    min-width: 60px;
    max-width: 60px;
    margin-left: 10px;
}

.input-modifier-text60 {
    width: 100%;
    min-width: 60px;
    max-width: 60px;
    margin-left: 10px;
    margin-top: 26px;
}

.input-procedure-text100 {
    width: 100%;
    min-width: 100px;
    max-width: 100px;
    margin-left: 10px;
}

.input-procedure-text200 {
    width: 100%;
    min-width: 180px;
    max-width: 200px;
    margin-left: 10px;
}

.input_calendar {
    width: 100%;
    max-width: 200px;
    margin-left: 10px; 
}

#remove_text:hover {
    text-decoration: underline !important;
    cursor: pointer !important;
}

.input-modifier {
    max-width: 40px !important;
    margin-left: 10px;
}

.input_procedure1{
  max-width: 110px !important;
    margin-left: 10px;
}

.input_emg {
    max-width: 50px !important;
    margin-left: 10px;
}

.input_epsdt {
    max-width: 80px;
    margin-left: 10px;
    width: 100%;
}

.responsibility_procedure{
     min-width: 55px !important;
    margin-left: 10px;
    width: 180px;
}
.margin-top_-3 {
    margin-top: -3px !important;
}

/*nav dropdown*/

ul.navBarDropdown-menu {
    display: none;
    width: 250px !important;
    font-size: .875rem;
    padding: 0;
    right: 1px !important;
    margin-left: -2px;
    margin-top: -11px;
    /* margin-right: 8px; */
    box-shadow: -4px -3px 17px 0px #6a6a6a29 !important;
}

ul.navBarDropdown-menu.show {
    display: block !important;
    background-color: #ffffff !important;
    color: #16181b !important;
    list-style-type: none !important;
}

ul.navBarDropdown-menu li:last-child a {
    border: none !important;
}


ul.navBarDropdown-menu li {
    padding: 0px !important;
}

ul.navBarDropdown-menu li a {
    display: block;
    border-bottom: 1px solid #EBECEC;
    padding: 10px;
    text-align: left;
    font-size: 0.875rem;
    text-decoration: none;
        color: #16181b !important;
}

.link {
    color: #606162;
}

ul.navBarDropdown-menu li:hover {
    background: #fbfbfb !important;
    text-decoration: none;
    color: #16181b !important;
}

ul.navBarDropdown-menu li:hover a {
    background: #e9ecef !important;
    text-decoration: none;
    color: #16181b !important;
}



.rbt.input-error input {
    border-color: #EC5F60 !important;
}

.add-new-disabled {
    color: #bbbbbb !important;
}

div .dropdown-toggle:after {
    display: none;
}

div .dropdown-menu a {
    min-width: 10 !important;
}

.download-dropdown-right {
    font-size: 1.1rem !important;
    min-width: 150px !important;
    margin-top: 6px !important;
    border-bottom: none !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.dropdown-menu.dropdown-menu-right li:hover {
    background-color: #1074B9 !important;
}

.dropdown-menu.dropdown-menu-right li:hover a {
    color: #ffffff !important;
    background-color: #1074B9 !important;
}

img .cursor-pointer {
    width: 20px;
}

.fas.fa-caret-down {
    font-size: 14px !important;
    padding-left: 5px;
}

.fas.fa-caret-down.table-icon {
    font-size: 1.5rem !important;
}

.tmp-border {
    border: solid 1px #EC5F60;
}

/* Override the maxWidth style of the Popover in procedureActivityView.jsx */
.MuiPopover-paper {
    width: max-content !important;
    min-width: 300px !important;
    max-width:500px !important;
}
.right-content-w {
    position: fixed;
    height: 100%;
    background: #ffffff;
    right: -70%;
    -webkit-transition: .35s;
    transition: .35s;
    z-index: 60;
    top: 0;
    bottom: 0;
    box-shadow: 0 3px 15px rgb(0 0 0 / 50%);
  }
  
  .right-content-w .mCSB_dragger {
    opacity: 0;
  }
  
  .right-content-w:hover .mCSB_dragger {
    opacity: 1;
  }
  
  .right-content-w .settings-menu {
    height: 100%;
    padding-bottom: 72px;
  }
  
  .right-content-w ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .right-content-w ul li Link {
    color: #ffff;
    text-decoration: none;
    font-weight: 800;
    font-size: 1rem;
    padding: 9px 18px;
    display: block;
    padding-left: 30px;
  }
  
  .right-content-w ul li.head {
    background: #ffffff;
    border-bottom: 1px solid #dddd;
    padding: 18px;
  }
  
 
  
  .right-content-w.active {
    right: 0;
  }

  .right-content-w ul li a,
  .right-content-w ul li.head{
  padding-left: 20px !important;
}

.right-panel-box {
  padding-left: 25px !important;
  padding-right: 25px !important;
  padding-bottom: 20px !important;
  padding-top: 0px !important;
  height: 100% !important;
} 
table{
    width: 100% !important;
}

.table th {
    position: relative;
}
.resizer {
    /* Displayed at the right side of column */
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    cursor: col-resize;
    user-select: none;
}

.resizer:hover,
.resizing {
    border-right: 2px solid blue;
}

.longTableHead{
    max-width: 100% !important;
}

.shortTableHead{
    max-width: 30% !important;
}

.max-width_21percent{
    max-width: 21% !important;
}
.white-bg{
	background-color: white;
}

.password-strength {
    margin-top: -20px;
    width: 100%;
    display: flex;
    gap: 5px;
}

.strength-bar {
    flex: 1;
    height: 4px;
    background-color: grey;
}

.red {
    background-color: #FF0000; /* Bright red */
}

.light-red {
    background-color: #FF7F7F; /* Light red */
}

.blue {
    background-color: #3b6291; /* Bright yellow */
}

.green {
    background-color: #008000; /* Dark green */
}
html {
    height: 100%;
    width: 100%;
    display: table;
    margin: 0;
    background: #F4F4F4;
    color: #2E2F30;
    font-size: 13px;
    -webkit-transition: .45s;
    transition: .45s;
}

body {
    font-family: 'Lato';
    margin: 0;
}

.content-section {
    margin-top: 1%;
}

.content-section .box {
    background: #ffffff;
    -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.09);
    border-radius: 2px;
}
.zindex-15 {
    z-index: 15 !important;
}

.container {
    width: 100% !important;
    max-width: none !important;
}

.content-section .box .box-head {
    /* padding-right: 10px;
    padding-left: 10px; */
    padding-bottom: 5px;
    height: auto;
    /* made overflow visible so AsyncDropDown-List will be legible -JS*/
        overflow: visible;
    min-height: 35px;
}

.dataTables_filter {
    float: left;
    position: relative;
    top: 0px;
}

.dataTables_filter button {
    min-width: 90px;
}

.dataTables_filter label {
    margin-bottom: 0;
}

.dataTables_filter label:before {
    position: absolute;
    content: "\f002";
    font-family: 'Line Awesome Free';
    font-weight: 900;
    color: #C8C8C8;
    font-size: 15px;
    left: 12px;
    top: 7px;
}

.dataTables_filter input {
    border: 0px solid #C0C0C0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    height: 32px;
    min-width: 285px;
    border-radius: 3px;
    margin-left: 1px;
    padding: 7px;
    font-size: 0.875rem;
    padding-left: 30px;
    max-width: 100%;
    background-color: #eff1f2;
}

.dataTables_filter .btn-outline-primary {
    border-color: #1074B9;
    color: #1074B9;
}

.btn-outline-primary i.la-file-import {
    color: #1074B9;
}

.btn-outline-primary i.la-file-download {
    color: #1074B9;
}

.btn.btn-outline-primary:hover i {
    color: #FFF;
}

.btn.btn-outline-primary {
    display: inline-table
}

.dataTables_filter .btn-outline-primary i.la-calendar {
    font-size: 1.2rem !important;
    position: relative;
    top: 2px;
}

.dataTables_filter .btn-outline-primary:hover {
    background: #1074B9;
    color: #ffffff;
}

.dataTables_filter .btn-outline-primary.active {
    background: #1074B9 !important;
    border-color: #1074B9 !important;
}

.alignCenter {
    float: center;
}

.alignRight {
    float: right;
}

.alignLeft {
    float: left;
}

.minWidth30 {
    min-width: 30%;
}

.minHeight {
    height: 88vh !important;
}


.word-wrap {
    word-break: break-word;
    white-space: pre-line;
    overflow-wrap: break-word;
    word-wrap: break-word !important;
}

.table-border-radius8 {
    border-radius: 8px !important;
}

.table-border-radius10 {
    border-radius: 10px !important;
}

.table-border-radius12 {
    border-radius: 12px !important;
}

.table-border-radius15 {
    border-radius: 15px !important;
}

.table tr td .dropdown {
    width: 1px;
    position: relative;
    right: 10px;
}

.table tr td .dropdown-toggle {
    border: none;
    background: none;
    font-size: 1rem;
    line-height: 0;
    outline: none;
    color: #4d4b4b;
}

.table tr td .dropdown-toggle:after {
    display: none;
}

.link {
    color: #1074B9;
    cursor: pointer;
}

.link:hover {
    text-decoration: auto;
    color: #21315F;
}

.dictionaries-back {
    font-size: 12px !important;
    padding-left: 0px !important;
}

.addNew {
    font-size: 13px;
    color: #1074B9;
    cursor: pointer;
    font-weight: bold;
}

.addNew:hover {
    text-decoration: auto;
    color: #21315F;
}

.modalCloseBtn {
    color: #090909 !important;
}

.form-group {
    width: 100%;
    position: relative;
}

.form-group input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    height: 36px;
    width: 100%;
    font-size: 14px !important;
    position: relative;
    z-index: 1;
    background: none;
    font-weight: 400;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.Mui-focused div[tabIndex="0"] {
    padding-left: 5px !important;
}

.form-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    height: 32px;
    width: 100%;
    border-radius: 3px;
    font-size: 14px !important;
    position: relative;
    background: none;
    padding-left: 8px !important;
    font-weight: 400;
}

.select-input select {
    padding-right: 45px;
    padding-left: 15px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.select-input:after {
    content: '\f0d7';
    font-family: 'Line Awesome Free';
    font-weight: 900;
    font-size: 1.2rem;
    position: absolute;
    color: rgba(116, 120, 141, 0.8);
    right: 10px;
    top: 4px;
}

.form-group textarea {
    font-size: 14px !important;
}

.modal {
    text-align: center;
    padding: 0 !important;
    z-index: 1300 !important;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
}

.modal:before {
    content: '';
    display: inline-block;
    height: 50%;
    vertical-align: middle;
    margin-right: -4px;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    min-width: 500px;
}

.input-error {
    border-color: #EC5F60 !important;
}

.input-error-bottom {
    display: block;
}

div.rbt.input-error-bottom div input {
    border: 1px solid #EC5F60 !important;
}

div.rbt.input-error-bottom div input~input {
    border-bottom: none !important;
}

.dropdown-menu.show {
    position: absolute;
    will-change: transform;
    transform: translate3d(10px, 42px, 0px);
    top: 0px;
    left: 0px;
    color: #000;
}

.patientList.dropdown-menu.show {
    position: absolute;
    will-change: transform;
    transform: translate3d(10px, 42px, 0px);
    top: 0px;
    left: -100px !important;
    color: #000;
    box-shadow: -4px -3px 17px 0px #6a6a6a29 !important;
}

.patientList.dropdown-menu::before {
    content: "";
    width: 16px;
    height: 16px;
    background: #ffffff;
    position: absolute;
    top: -8px;
    z-index: -1;
    right: 20px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-right: none;
    border-bottom: none;
}


.table tr td .dropdown-menu {
    font-size: .875rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 10px 0 rgba(106, 106, 106, 0.16);
    box-shadow: 0 0 10px 0 rgba(106, 106, 106, 0.16);
    min-width: 6rem !important;
}

.table tr td .dropdown-menu a.edit {
    color: #2D2F31;
    border-bottom: 1px solid #F4F4F4;
}

.table tr td .dropdown-menu a.delete {
    color: #EC5F60;
}

/* .table tr td .dropdown-menu:before {
    content: "";
    width: 16px;
    height: 16px;
    background: #ffffff;
    position: absolute;
    top: -8px;
    right: 24px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-box-shadow: -4px -3px 7px 0 rgba(106, 106, 106, 0.16);
    box-shadow: -4px -3px 7px 0 rgba(106, 106, 106, 0.16);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-right: none;
    border-bottom: none;
} */

.table tr td .dropdown-menu {
    position: absolute;
    transform: translate3d(-25px, 30px, 0px);
    top: -10px !important;
    left: 0px !important;
    width: 120px;
}

.table tr td .dropdown-menu a {
    margin: 0 12px;
    padding-left: 6px;
    padding-right: 6px;
}

/* 
.table tr td .dropdown-menu:before {
    content: "";
    width: 16px;
    height: 16px;
    background: #ffffff;
    position: absolute;
    top: -8px;
    right: 24px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-box-shadow: -4px -3px 7px 0 rgba(106, 106, 106, 0.16);
    box-shadow: -4px -3px 7px 0 rgba(106, 106, 106, 0.16);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-right: none;
    border-bottom: none;
} */

.table tr td .dropdown-menu .dropdown-item:active {
    background: none;
}

.dropdown-item {
    font-size: 12px !important;
}

.box-content-white {
    background-color: #fff;
    border-radius: 8px;
    padding: 10px 15px 10px 15px !important; 
}
.box-content-padding {
    padding-left: 8px;
    padding-right: 7px;
}

.border-radius-topleft-0 {
    border-top-left-radius: 0px !important; 
}

.back-arrow {
    color: #1479BB !important;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.back-arrow:hover {
    color: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), #1479BB;  
    cursor: pointer !important;
} 

.table-responsive {
    padding-bottom: 5px !important;
    padding-top: 5px;
}

.alert-dismissible .close {
    /* padding: .50rem 1.25rem !important; */
}

.toast {
    position: fixed !important;
    left: 50%;
    transform: translate(-50%, 0);
    top: 2%;
    z-index: 9999;
}

.warning {
    background-color: #fffaf3 !important;
    border-color: #ddd2be !important;
    color: #7a4d05 !important;
}

.text-warning {
    color: #7a4d05 !important;
}

.success {
    background-color: #fcfff5 !important;
    border-color: #c4dbb7 !important;
    color: #1e561f !important;
}

.text-success {
    color: #1e561f !important;
}

.info {
    background-color: #f8ffff !important;
    border-color: #d8f5f9 !important;
    color: #0e566c !important;
}

.text-info {
    color: #0e566c !important;
}

.error {
    background-color: #fff6f6 !important;
    border-color: #955856 !important;
    color: #983c3a !important;
}

.error_text {
    font-family: Lato !important;
    color: #f53845 !important;
    font-size: 0.75rem;
    line-height: 1.66;
}

.text-error {
    color: #983c3a !important;
}

table {
    border: 1px solid #ECECEF !important;
}

thead {
    background-color: #F9FAFB;
    border-width: 0px;
}

/* thead tr {
    height: 30px;
} */

.rightside-menu-text {
    font-size: 14px !important;
    cursor: pointer;
}

.rsdt.rsdt-sortable-icon:after,
.rsdt.rsdt-sortable-asc:after,
.rsdt.rsdt-sortable-desc:after {
    content: '\f0dc' !important;
    font-family: 'Line Awesome Free' !important;
    font-weight: 900 !important;
    font-size: 1rem !important;
    right: 10px !important;
    top: 2px !important;
    opacity: 1 !important;
    position: relative;
}

.rsdt-paginate {
    text-align: right !important;
    margin-top: 2px;
}

.rsdt-paginate.button:first-child {
    border-radius:5px !important;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input:focus{
    background-color: none !important;
}

.rsdt-paginate.button {
    border: 1px solid #D1D2D2 !important;
    margin-left: 5px !important;
    padding: 5px 8px !important;
    min-width: 30px !important;
    text-align: center !important;
    border-radius: 5px !important;
    background-color: #fff !important;
    color: #545455 !important;
    font-size: .875rem !important;
}

.rsdt-paginate.button.active {
    color: #1479BB !important;
    background-color: #fff !important;
    border-color:#1479BB !important ;
}

.rsdt-paginate.button:nth-last-child(1) {
    border-radius: 5px !important;
    border-right: 1px solid #D1D2D2 !important;
}

button.rsdt-paginate.button:hover {
    color: #545455 !important;
    background-color: #DFDFDF !important;
}

.rsdt-paginate button[disabled=disabled],
.rsdt-paginate button:disabled,
.rsdt-paginate button[disabled=disabled]:hover,
.rsdt-paginate button:disabled:hover {
    color: #d5d5d5 !important;
    cursor: unset;
    background-color: transparent !important;
}

textarea {
    padding: 10px;
}

.checkbox-wrapper {
    width: 100%;
    position: relative;
    min-height: 32px;
}

.checkbox-wrapper .custom-checkbox,
.checkbox-wrapper .custom-radio {
    top: 5px;
}

/*custom checkbox*/

.custom-checkbox {
    display: inline-block;
}

.custom-checkbox label.labeltext {
    display: inline-block;
    padding-left: 6px;
    font-size: 0.875rem;
    margin-right: 12px;
}

.custom-checkbox input[type="checkbox"] {
    display: none;
}

.custom-checkbox input[type="checkbox"]+label.checkbox {
    margin-bottom: 0;
    position: relative;
    width: 16px;
    height: 16px;
    top: 3px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #ffffff;
    cursor: pointer;
}

.custom-checkbox input[type="checkbox"]+label.checkbox:before {
    border-radius: 2px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #C0C0C0;
    text-align: center;
}

.custom-checkbox input[type="checkbox"]+label.checkbox:after {
    background-color: transparent;
    content: "";
    display: block;
    position: absolute;
    left: 5px;
    top: 2px;
    width: 6px;
    height: 10px;
    opacity: 0;
    border-bottom: 2px solid #2D2F31;
    border-right: 2px solid #2D2F31;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease;
}

.custom-checkbox input[type="checkbox"]:checked+label.checkbox:before {
    content: "";
}

.custom-checkbox input[type="checkbox"]:checked+label.checkbox:after {
    content: "";
    opacity: 1;
}

/*custom radio*/

.custom-radio {
    display: inline-block;
}

.custom-radio label.labeltext {
    display: inline-block;
    padding-left: 6px;
    font-size: 0.875rem;
    margin-right: 12px;
}

.custom-radio input[type="radio"] {
    display: none;
}

.custom-radio input[type="radio"]+label.radio {
    margin-bottom: 0;
    width: 16px;
    height: 16px;
    position: relative;
    top: 2px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #ffffff;
}

.custom-radio input[type="radio"]+label.radio:before {
    border-radius: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #C0C0C0;
    text-align: center;
}

.custom-radio input[type="radio"]+label.radio:after {
    background-color: transparent;
    content: "";
    display: block;
    position: absolute;
    left: 5px;
    top: 2px;
    width: 6px;
    height: 10px;
    opacity: 0;
    border-bottom: 2px solid #2D2F31;
    border-right: 2px solid #2D2F31;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: border-color 0.3s ease;
    transition: border-color 0.3s ease;
}

.custom-radio input[type="radio"]:checked+label.radio:before {
    background: #0075E3;
    border-color: #0075E3;
}

/*custom radio*/

.custom-radio input[type="radio"]:checked+label.radio:after {
    content: "";
    opacity: 1;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: #ffffff;
    border: none;
    top: 5px;
}

.calendar-input .react-datepicker__input-container:before,
.calendar-input .react-datepicker__input-container:after {
    display: none !important;
    z-index: 10;
}

.calendar-input .react-datepicker__input-container input {
    padding-left: 12px !important;
    z-index: 1;
}

.customDatePickerWidth,
.customDatePickerWidth>div.react-datepicker-wrapper,
.customDatePickerWidth>div>div.react-datepicker__input-container .customDatePickerWidth>div>div.react-datepicker__input-container input {
    width: 100%;
}

.dictLabel {
    padding-left: 10px;
    color: #777676;
}

.importBtn {
    padding: 3px;
    width: 30px;
    height: 30px;
    border-radius: 30px !important;
}
.btn.importBtn.disabled {
    opacity: 0.6; 
    cursor: not-allowed; 
    pointer-events: none; 
  }

.importBtn i {
    font-size: 1.7rem;
}

.disInlineBlock {
    display: inline-block;
    font-size: 16px !important;
}

.btn-group .btn {
    margin-top: 0px !important;
}
/* 
button.btn-danger-red i {
    color: #1074B9 !important;
}

button.btn-danger-red:hover i {
    color: #fff !important;
}
button.btn-secondary i {
    color: #1074B9 !important;
}

button.btn-secondary:hover i {
    color: #fff !important;
} */
/* button.btn-primary-blue i {
    color: #1074B9 !important;
} */

button.btn-primary-blue:hover i {
    color: #fff !important;
}

.btn-primary-blue {
    color: #fff !important;
    background-color: #1479BB !important;
    border-color: #1479BB !important;
    border-radius: 25px !important;
    font-weight: 400 !important;
    padding: 0.4rem 2rem;
    height: 36px !important;
        border-radius: 5px !important;
        /* background-color: #1c3664 !important; */
}

.btn-primary-blue-outlined {
    color: #1479BB !important;
    background-color: #fff !important;
    border:1px solid #1479BB !important;
    border-radius: 25px !important;
    font-weight: 400 !important;
    padding: 0.4rem 2rem;
    height: 32px !important;
        border-radius: 5px !important;
        /* background-color: #1c3664 !important; */
}

button.btn-round-blue:hover i {
    color: #fff !important;
}
.btn-round-blue {
    color: #1074B9 !important;
    background-color: #fff !important;
    border-color: #1074B9 !important;
    border-radius: 50% !important;
    /* background-color: #1c3664 !important; */
}

.btn-common-height28.btn-primary-blue {
    padding: 4px 20px;
    border-radius: 5px;
}

.btn-round-blue:hover {
    color: white !important;
    background-color: #1479BB !important;
}
.btn-primary-blue:hover,.btn-primary-blue:focus{
     color: #fff !important;
    background: linear-gradient(0deg,rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%) #1479BB !important
}

.btn-primary-blue-outlined:hover {
    color: #1479BB !important;
    background-color: #fff !important;
    border:1px solid #1479BB !important;
    border-radius: 25px !important;
    font-weight: 400 !important;
    padding: 0.4rem 2rem;
    height: 32px !important;
        border-radius: 5px !important;
        /* background-color: #1c3664 !important; */
}

.btn-secondary {
    color: var(--primary-p-2, #1479BB) !important;
    font-feature-settings: 'clig' off, 'liga' off !important;
    font-family: Lato !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;

    display: inline-flex !important;
    padding: 8px 16px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 10px !important;

    border-radius: 4px !important;
    border: 1px solid var(--primary-p-2, #1479BB) !important;
    background-color: #fff !important;
}

.btn-danger-red {
    background-color: #fff;
    color: #d4111b;
    border-color: #d4111b !important;
    padding: 0.45rem 2rem;
    border-radius: 25px !important;
}

.btn-danger-red:hover {
    background-color: #d4111b;
    color: #fff;
}

.btn-secondary:hover {
    border-radius: 4px !important;
    border: 1px solid var(--primary-p-2, #1479BB) !important;
    background: rgba(20, 121, 187, 0.10) !important;
}

.btn {
    font-weight: bold;
}

/*Tab*/

.tab-content {
    min-width: 95px;
    margin-top: 6px;
}

.nav-pills {
    /* background-color: #f5f5f5; */
    border-bottom: 1px solid #1c3664;
}

.nav-item>a {
    background-color: #f5f5f5;
}

.nav-pills .nav-link {
    color: #606162;
    font-size: 14px !important;
    cursor: pointer;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-left: 10px;
    margin-right: 4px;
    border: 1px solid #e0e0e0;
    border-bottom: 0px !important;
}

.nav-pills .nav-link:hover {
    background: #1c3664;
    color: #fff;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-left: 10px;
    margin-right: 4px;
    border: 1px solid #1c3664;
}

.nav-pills .nav-link.active {
    background: #1c3664;
    color: #fff;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-left: 10px;
    margin-right: 4px;
    border: 1px solid #1c3664;
}

.company-logo input {
    display: none;
}

.company-logo label {
    position: absolute;
    top: 30px;
    right: -15px;
    width: 35px;
    height: 35px;
    background: #fff;
    color: #2d2f31 !important;
    border-radius: 100%;
    border: 2px solid #eee;
    box-shadow: 0 0 4px rgba(0, 0, 0, .2);
    cursor: pointer;
}

.modal-body .company-logo label {
    top: 4px;
    right: -3px;
}

.company-logo label i {
    font-size: 1.3rem;
    position: relative;
    top: 5px;
    color: black;
}

.modal-body .company-logo img {
    width: 130px;
    height: 130px;
    border-radius: 100%;
}

.modal-body .company-logo .radius0 img {
    border-radius: 0% !important;
}

.disabledInput {
    background-color: #cccccc !important;
}

.disabledLabel {
    color: #cccccc !important;
}

hr {
    border-top: 1px solid #eeeeee;
}

.saveBtn {
    margin-right: 30px !important;
    width: 70px !important;
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100%;
}

.react-datepicker-popper {
    z-index: 10;
}

.cropImageModalBody {
    height: 470px;
}

.cropImageModalFooter {
    display: block;
}

.cropImageModalFooter span {
    color: #1074B9 !important;
}

.cropImageModalBody .cropImageDiv {
    margin-top: 460px;
}

.navTabs {
    padding-left: 10px !important;
}

.advancedSearch {
    padding-left: 0;
    font-size: 13px;
    color: #1074b9;
    cursor: pointer;
    font-weight: bold;
}

.advancedSearch i {
    padding-right: 5px;
    color: #1074B9;
}

.textCenter {
    text-align: center !important;
}

.textLeft {
    text-align: left !important;
}

.textRight {
    text-align: right !important;
}

.bgRed {
    background-color: rgb(235, 135, 135);
}

.bgBaseRed {
    background-color: rgb(255, 0, 0);
}

.bgBaseGreen {
    background-color: rgb(0, 128, 0);
}

.bg-grey {
    background-color: #606162 !important;
}

.bgBaseBlue {
    background-color: rgb(0, 0, 255);
}

.bgBaseWhite {
    background-color: rgb(255, 255, 255);
}

.txtBaseRed {
    color: rgb(255, 0, 0);
}

.txtBaseGreen {
    color: rgb(0, 128, 0);
}

.txtBaseBlue {
    color: rgb(0, 0, 255);
}

.txtBaseWhite {
    color: rgb(255, 255, 255);
}

.subscriberInfoHeaderColor {
    background-color: #a64d79;
    color: #ffffff;
}

.requestInfoHeaderColor {
    background-color: #2d4a7c;
    color: #ffffff;
}

.bgActiveStatusColor {
    background-color: #17db19;
}

.bgServiceNWColor {
    background-color: #dfe5e0;
}

.bgFailedStatusColor {
    background-color: #e02e11;
}

.expandDatas {
    cursor: pointer;
}

::placeholder {
    color: #9a9a9a;
}

.lh-0 {
    line-height: 0%;
}

.lh-1 {
    line-height: 10px !important;
}

#Layer_1 {
    fill: white;
}

.bottom {
    position: absolute;
    bottom: 0;
}

.justify-left {
    display: flex;
    justify-content: flex-start; 
}

.justify-right {
    display: flex;
    justify-content: flex-end;
}

.justify-center {
    display: flex;
    justify-content: center;
}

.chk-label {
    margin-top: 8px !important;
    margin-left: 8px !important;
}

.header-btn {
    font-size: 1rem !important;
    line-height: 1.5 !important;
}

.font-bold {
    font-weight: 900 !important;
}

.bold-font {
    font-weight: bold;
}

.warning-pop-up-span {
    border: 1px solid #ffd586 !important;
    border-radius: 3px;
    color: red !important;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #ffe5b4 !important;
}

.warning-pop-up-span span.round {
    color: #606162;
    justify-content: center;
    background: #ffe5b4;
    font-size: 11px;
    border-radius: 100%;
    border: 1px solid #ffd586;
    position: absolute;
    z-index: 1;
    cursor: pointer;
}

.warning-pop-up-span span.round i {
    color: #606162 !important;
}

.border-warning_exclamation {
    border: 1px solid #ffd586 !important;
}

.warning-div {
    margin-top: 5px;
    margin-bottom: 20px;
    margin-left: 5px;
}

.warning-info-img {
    margin-left: 5px;
    color: #EC5F60 !important;
}

.dot {
    height: 5px;
    width: 5px;
    background-color: #606162;
    border-radius: 50%;
    display: inline-block;
}

.warning_label label {
    font-weight: lighter !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

.clear-b-scroll-sector {
    margin-bottom: 10px;
}

.clear-b-scroll-sector5 {
    margin-bottom: 5px;
}

.disabled-link {
    pointer-events: none !important;
    color: #777676 !important;
}

.inactive-chk {
    display: flex;
    width: 20px;
}

.color-white {
    color: white;
}

.Mui-focused .MuiSelect-selectMenu {
    height: auto;
    overflow: hidden;
    padding-left: 5px !important;
    min-height: 1.1876em;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.MuiSelect-selectMenu {
    height: auto;
    overflow: hidden;
    padding-left: 0px;
    min-height: 1.1876em;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.MuiFormLabel-asterisk {
    color: #fff;
}

.MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 0px solid #ced4da !important;
    pointer-events: none;
}

.MuiInput-underline::after {
    border-bottom: 0px solid #ced4da !important;
}


.MuiGrid-container .MuiInputLabel-shrink {
    left: 0px !important;
}

.tr-selected {
    background-color: #dddd !important;
}

.color-blue {
    color: #1c3664;
}

.margin-top10 {
    margin-top: 10px !important;
}

.margin-top15 {
    margin-top: 15px !important;
}

.margin-top-16 {
    margin-top: 16px !important;
}

.margin-top17 {
    margin-top: 17px !important;
}

.margin-bottom-12 {
    margin-bottom: 12px !important;
}

.height-47 {
    height: 47px !important;
}

span i.fa-envelope {
    color: #707070 !important;
    font-size: 16px !important;
    margin-bottom: 0px !important;
}

span.email_icon {
    position: absolute !important;
    bottom: 0px !important;
    height: 33px !important;
    padding-top: 8px !important;
    padding-left: 4px;
    border-bottom: 1px solid #ced4da !important;
}

span.email_icon.focus {
    background-color: #f0f0f0 !important;
}

.email_label {
    position: absolute !important;
    top: 5px !important;
    left: 25px !important;
    color: #707070 !important;
    line-height: 10px !important;
    transform: translate(0, 24px) scale(1) !important;
}

.email_label.focus {
    position: absolute !important;
    top: 3px !important;
    left: 0px !important;
    color: #707070 !important;
    line-height: 10px !important;
    transform: translate(0, 1px) scale(1) !important;
}

.showLink {
    display: "" !important;
    color: #EC5F60 !important;
    margin-left: -10px !important;
}

.margin-top-10 {
    margin-top: -10px;
}
.margin-topM4 {
    margin-top: -4px !important;
}

.margin-leftM15 {
    margin-left: -15px !important;
}

.margin-top-n16 {
    margin-top: -16px;
}

.addNew-btn {
    margin-top: 17px !important;
    margin-left: 25px !important;
}

.invalid-link {
    height: 147px !important;
    width: 547px !important;
}

.forgetpassword-span {
    font-size: 18px;
    padding: 25px;
    position: absolute;
    margin-top: 30px;
}

.edit-icon {
    margin-left: 3px;
    color: #1c3664;
    cursor: pointer;
}

.display-flex {
    display: flex;
}

.th_padding15 tr>* {
    padding: 15px !important;
}

.update-btn {
    background-color: #fff !important;
    color: #1479BB !important;
    margin-left: 5% !important;
    height: 30px !important;
    border-radius: 4px !important;
    margin-right: 10rem !important;
    font-weight: 500;
    line-height: 0px !important;
}

.update-btn:hover{
    background-color:#fff !important;
    color: #1479BB !important;
    margin-left: 5% !important;
    height: 30px !important;
    border-radius: 4px !important;
    margin-right: 10rem !important;
    font-weight: 500;
    line-height: 0px !important;
}

.MuiAutocomplete-popper {
    min-width: 200px !important;
    /* width: 100% !important; */
}

.display-grid {
    display: grid;
}


.cityDropdown_content {
    display: block;
    cursor: pointer;
    border: 1px solid #dddd;
    position: absolute;
    background-color: #fff !important;
    box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 10%);
    z-index: 6;
    width: 100%;
    margin-top: -13px !important;
}

.cityDropdown_content ul {
    display: contents !important;
    list-style-type: none !important;
    margin: 0px !important;
    box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 10%);
}

.cityDropdown_content ul li:hover {
    background-color: #1c3664 !important;
    color: #fff !important;
}

.cityDropdown_content ul li {
    cursor: pointer !important;
    border-bottom: 1px solid #dddd !important;
    padding: 5px 10px !important;
}

.cityDropdown_content ul li:last-child {
    border-bottom: none !important;
}

.list_style_type_none {
    list-style-type: none !important;
}

.text-overflow {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    max-width: 250px !important;
}

.scroll {
    overflow-x: auto !important;
    width: 100% !important;
}

table thead tr th {
    border: 1px solid #f6f0f0;
    white-space: nowrap !important;
    /* text-align: center !important; */
    /* min-width: 125px !important; */
    /* width: 100% !important; */
}

#inbox_table thead tr th {
    text-align: center;
    border: 1px solid #f6f0f0;
}

#inbox_table tbody tr td {
    border: 1px solid #f6f0f0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px !important;
}

#inbox_table tbody tr:hover {
    box-shadow: 0px 2px 18px 0px #6c6c6c80;
    cursor: pointer;
    position: relative;
}

#inbox_table tbody tr.readed {
    background-color: #f3f6f6 !important;
}

.readed {
    background-color: #f3f6f6 !important;
}
.unreaded {
    background: rgba(36, 167, 224, 0.10) !important;
}
.isreadinbox {
    font-weight: 600 !important;
}

table tr td {
    border: 1px solid #f0f0f0;
}

.table tbody tr td:not(.noVerticalAlign) {
    /* this will apply vertical algin to all tds except with the class name  noVerticalAlign */
    vertical-align: middle !important;
}

.input_right_align input {
    text-align: right;
    padding-right: 0px !important;
}

.col-md-12 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.fixed_head thead {
    position: sticky;
    top: -1px !important;
}

.margin-top-25 {
    margin-top: 25px !important;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

/* i.fas,
i.far {
    color: #000000;
    font-size: 1.5rem;
} */

ul li a i.fas {
    color: #ffffff;
    font-size: 1rem;
}

ul li a:hover i.fas {
    color: #1074B9;
}

.grid-inline {
    display: grid;
    text-align: center;
}

.header a {
    text-decoration: none;
    color: #000;
}

.header_icon:hover {
    border: 1px solid #1074B9;
    box-shadow: 0px 2px 2px #1074b9;
}

.header_icon:active {
    border: 1px solid #ffffff;
    box-shadow: 0px;
}

.small_box :first-child {
    padding-right: 1rem;
}

.small_box i.fas,
.small_box i.far,
.small_box i.fa {
    font-size: 1rem !important;
    color: #ffffff;
}

.file-icons {
    color: #1074B9 !important;
    font-size: 20px !important;
}

.file-icons-red {
    color: #e51b0c !important;
    font-size: 20px !important;
}

.grid-icon-span {
    cursor: pointer;
    padding: 2px !important;
}

.chk-column {
    padding-top: 12px !important;
    width: 240px !important;
}

.max-width-none {
    max-width: none !important;
}

.small-red-text {
    color: #EC5F60 !important;
    font-size: 12px !important;
}

.assigne-select {
    padding-right: 0 !important;
    padding-left: 17px !important;
}


.tblComponentMinWidth300 {
    min-width: 300px !important;
}

.export-icon {
    font-size: 30px !important;
    padding: 3px;
}

.margin-export-icon {
    margin-top: 2px !important;
    margin-left: 4px !important;
}

.btn-padding {
    padding: .4rem 1rem !important;
}

.navBarIcons {
    color: #3a3b3c !important;
    font-size: 20px;
    background-color: #F5F5F5 !important;
    padding: 6px 6px 6px 7px;
    cursor: pointer !important;
    /* background-color: #fbfbfb; */
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    box-shadow: inset !important;
}

.navBarIcons:hover {
  color: #3a3b3c !important;
    font-size: 20px;
    background-color: #F5F5F5 !important;
    padding: 6px 6px 6px 7px;
    cursor: pointer !important;
    /* background-color: #fbfbfb; */
    border-radius: 4px;
    border: 1px solid #E0E0E0;
    box-shadow: inset !important;
}

.searchListIcons {
    position: absolute;
    right: -13px;
    top: 0px;
}

table tr td .leftSide.dropdown-menu.show {
    position: absolute !important;
    will-change: transform !important;
    transform: translate3d(10px, 42px, 0px) !important;
    top: -18px;
    left: -100px !important;
    color: #000;
    box-shadow: -4px -3px 17px 0px rgba(106, 106, 106, 0.16) !important;
}

table tr td .leftSide.dropdown-menu::before {
    content: "";
    width: 16px !important;
    height: 16px !important;
    background: #ffffff;
    position: absolute !important;
    top: -7px;
    z-index: -1 !important;
    right: 10px !important;
    -webkit-transform: rotate(45deg) !important;
    transform: rotate(45deg) !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-right: none !important;
    border-bottom: none !important;
}

.btn:focus {
    box-shadow: none !important;
}

.btn-select-height3135 {
    height: 31px;
    min-width: 35px !important;
    max-width: 35px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    font-weight: bold;
}

/* #tr-hover tbody tr:hover {
    background-color: #fbfbfb;
} */

table tbody tr:hover {
    background-color: #fbfbfb;
}

.div-100-perc {
    height: 100%; 
    width: 100%;
}

.border-2 {
    border-width:2px !important;
}

.border-cl-purple {
    border-color: #9900ff !important;
}
div.dropdown-menu:before {
    content: "";
    width: 16px;
    height: 16px;
    background: #ffffff;
    position: absolute;
    top: -10px;
    left: 13px;
    transform: rotate(45deg);
    box-shadow: -4px -3px 7px 0 rgb(106 106 106 / 16%);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-right: none;
    border-bottom: none;
}
.table-fixed_head-zindex thead {
    position: sticky;
    top: -1px !important;
    z-index: 5;
}

.export_button{
    font-family: 'Lato';
    border-radius: 4px !important;
    border: 1px solid #1479BB;
    font-weight: 400;
    padding:5px !important;
}

.procedure_box{
    background-color: #FAFAFA;
    border-radius:10px !important;
    margin-top: 20px;
    width: auto !important;
    border: 1px solid #E0E0E0;
}

.expan_box{
    cursor: pointer;
    background-color: #EEEEEE;
    border-bottom-left-radius:10px !important;
    border-bottom-right-radius: 10px !important;
    height: 30px; 
    justify-content: center;
    text-align: center;
    align-items: center;
    align-content: center;
    border-top: 1px solid #E0E0E0;
    margin-left: -15px; /* Negative margin to counter parent margin */
    margin-right: -15px; /* Negative margin to counter parent margin */
    width: calc(100% + 35px); /* Increase width to fill the space */
}

.return_to_inbox{
    color: #1074B9;
    margin-left: -8px;
}
.return_to_inbox i.fas{
    color: #1074B9;
    font-size: 1rem;
}

.reply_time_label{
    position: relative;
    top: -22px;
    left: 10px;
}

.message_dropddown {
    position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px !important;
    transform: translate3d(-17px, 40px, 0px) !important;
}

.refreshIcon {
    font-size: 20px !important;
    color: #959595 !important;
}
.dropbtn1 {
    background-color: #fff;
    padding: 5px;
    font-size: 12px;
    border: none;
    cursor: pointer;
  }
  
  .dropdown1 {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content1 {
    display: none;
    position: absolute;
    right: 0;
    background-color: #fff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content1 a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content1 a:hover {background-color: #f1f1f1;}
  
  .dropdown1:hover .dropdown-content1 {
    display: block;
  }
  
  .dropdown1:hover .dropbtn1 {
    background-color: #fff;
  }
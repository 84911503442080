#modal_button_screen{
    width: 100%;
    max-width: 470px !important;
}
#modal_button_screen.modal-dialog{
    min-width: 0% !important;
}
#modal_button_screen .modal-content{
    width: 100%;
    max-width: 470px !important;
}
#modal_button_screen .modal_button_screen_body{
    overflow: auto;
    max-height: 500px;
    padding: 0 15px !important;
}
.modal_button_screen_body input{
    width: 100%;
    margin-top: 10px;
    border: 1px solid #e0e0e0;
    padding: 5px;
}
.modal_button_screen_body ul{
    margin: 0;
    padding: 10px 0;
}
.modal_button_screen_body ul li label{
    margin: 0 0.2rem !important;
    padding: 0;
}
.modal_button_screen_checkbox{
    padding: 5px;
    border-radius: 2px;
}
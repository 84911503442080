/* lato-regular - latin */

@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/fonts/lato-v17-latin-regular.eot');
    /* IE9 Compat Modes */
    src: local(''), url('./assets/fonts/lato-v17-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('./assets/fonts/lato-v17-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('./assets/fonts/lato-v17-latin-regular.woff') format('woff'),
        /* Modern Browsers */
        url('./assets/fonts/lato-v17-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('./assets/fonts/lato-v17-latin-regular.svg#Lato') format('svg');
    /* Legacy iOS */
}

#root {
    background-color: #f2f2f2;
}

body {
    margin: 0;
    font-family: 'Lato';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ffff !important;
}

code {
    font-family: 'Lato', monospace;
}

/* i {
    color: white;
} */

/* span {
    color: white;
} */

.div-backto-dictionaties {
    padding-left: 16px; 
    margin-bottom: 7px;
    margin-top: 2px;
}

.px-15px {
    padding-left: 15px !important; 
    padding-right: 15px !important; 
}
    

.padding-top0 {
    padding-top: 0px !important;
}

.padding-top1 {
    padding-top: 1px !important;
}

.padding-top2 {
    padding-top: 2px !important;
}

.padding-top3 {
    padding-top: 3px !important;
}

.padding-top4 {
    padding-top: 4px !important;
}

.padding-top6 {
    padding-top: 6px !important;
}

.padding-top5 {
    padding-top: 5px !important;
}

.padding-top7 {
    padding-top: 7px !important;
}

.padding-top8 {
    padding-top: 8px !important;
}

.padding-top9 {
    padding-top: 9px !important;
}

.padding-top10 {
    padding-top: 10px !important;
}

.padding-top11 {
    padding-top: 11px !important;
}

.padding-top12 {
    padding-top: 12px !important;
}

.padding-top13 {
    padding-top: 13px !important;
}

.padding-top15 {
    padding-top: 15px !important;
}

.padding-top16 {
    padding-top: 16px !important;
}

.padding-top17 {
    padding-top: 17px !important;
}

.padding-top18 {
    padding-top: 18px !important;
}

.padding-top19 {
    padding-top: 19px !important;
}

.padding-top20 {
    padding-top: 20px !important;
}

.padding-top21 {
    padding-top: 21px !important;
}

.padding-top22 {
    padding-top: 22px !important;
}

.padding-top23 {
    padding-top: 23px !important;
}

.padding-top24 {
    padding-top: 24px !important;
}

.padding-top25 {
    padding-top: 25px !important;
}

.padding-top26 {
    padding-top: 26px !important;
}

.padding-top29 {
    padding-top: 29px !important;
}

.padding-top30 {
    padding-top: 30px !important;
}

.padding-top32 {
    padding-top: 32px !important;
}

.padding-top40 {
    padding-top: 40px !important;
}

.padding-top50 {
    padding-top: 50px !important;
}

.padding-top70 {
    padding-top: 70px !important;
}

.padding-left0 {
    padding-left: 0px !important;
}

.padding-left2 {
    padding-left: 2px !important;
}

.padding-left3 {
    padding-left: 3px !important;
}

.padding-left5 {
    padding-left: 5px !important;
}

.padding-left8 {
    padding-left: 8px !important;
}

.padding-left10 {
    padding-left: 10px !important;
}

.padding-left-15 {
    padding-left: -15px !important;
}

.padding-left7 {
    padding-left: 7px !important;
}

.padding-left12 {
    padding-left: 12px !important;
}

.padding-left15 {
    padding-left: 15px !important; 
}

.pr-pl-15 {
    padding: 0px 15px;
}

.padding-left16 {
    padding-left: 16px !important; 
}

.padding-left17 {
    padding-left: 17px !important;
}

.padding-left20 {
    padding-left: 20px !important;
}

.padding-left25 {
    padding-left: 25px !important;
}

.padding-left21 {
    padding-left: 21px !important;
}

.padding-left30 {
    padding-left: 30px !important;
}

.padding-left35 {
    padding-left: 35px !important;
}

.padding-left55 {
    padding-left: 55px !important;
}

.padding-left70 {
    padding-left: 70px !important;
}

.padding-left75 {
    padding-left: 75px !important;
}

.padding-left90 {
    padding-left: 90px !important;
}

.padding-left97 {
    padding-left: 97px !important;
}

.padding-left110 {
    padding-left: 110px !important;
}

.padding-left120 {
    padding-left: 120px !important;
}

.padding-left140 {
    padding-left: 140px !important;
}

.padding-left16 {
    padding-left: 16px !important;
}

.padding-right0 {
    padding-right: 0px !important;
}

.padding-right5 {
    padding-right: 5px !important;
}

.padding-right8 {
    padding-right: 8px !important;
}

.padding-right10 {
    padding-right: 10px !important;
}

.padding-right15 {
    padding-right: 15px !important;
}

.padding-right20 {
    padding-right: 20px !important;
}

.padding-right25 {
    padding-right: 25px !important;
}

.padding-right35 {
    padding-right: 35px !important;
}

.padding-bottom5 {
    padding-bottom: 5px !important;
}

.padding-bottom9 {
    padding-bottom: 9px !important;
}

.padding-bottom10 {
    padding-bottom: 10px !important;
}

.padding-bottom15 {
    padding-bottom: 15px !important;
}

.padding-bottom20 {
    padding-bottom: 20px !important;
}

.padding-bottom25 {
    padding-bottom: 25px !important;
}

.padding-bottom30 {
    padding-bottom: 30px !important;
}

.padding-bottom60 {
    padding-bottom: 60px !important;
}

.padding-bottom35 {
    padding-bottom: 35px !important;
}

.padding-bottom60 {
    padding-bottom: 60px !important;
}

.padding-bottom80 {
    padding-bottom: 80px !important;
}

.text-red {
    color: #EC5F60 !important;
}

.text-green {
    color: #30BB67 !important;
}

.width-half-right {
    width: 50% !important;
    text-align: right;
}

.width-half-left {
    width: 50% !important;
    text-align: left;
}

.center-div {
    width: 500px;
    height: fit-content !important;
    padding: 10px !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.center-div-with-shadow {
    width: 500px;
    height: fit-content !important;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    box-shadow: 0px 0px 20px 0px #d0cccc;
}

.textarea-100 {
    width: 100%;
}

.multiselect-container>li>a>label>input[type=checkbox] {
    margin-bottom: 5px !important;
    -webkit-appearance: checkbox;
    height: 21px;
}

.multiselect {
    /* border: 1px solid #C0C0C0 !important; */
    cursor: pointer !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    display: inline-block;
    margin-left: 0px !important;
    text-align: left !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 32px !important;
    font-size: .875rem;
    outline: none;
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
}

.multiselect:focus {
    border-color: #005CB9 !important;
}

span.multiselect-native-select {
    display: block;
}

.multiselect.dropdown-toggle::after {
    display: none !important;
}

.multiselect-native-select .btn-group {
    width: 100% !important;
}

.multisel_textbox .multiselect-container li {
    padding-bottom: 10px !important;
}

.multisel_textbox .multiselect-container {
    padding: 12px 0 !important;
    width: 100% !important;
}

.multisel_textbox .multiselect-container input[type=checkbox] {
    height: 20px;
    margin-left: -25px;
    width: 20px;
    -webkit-appearance: checkbox;
    position: absolute;
}

.multisel_textbox .multiselect-container li label {
    width: 66%;
    display: inline-block !important;
}


.custom-checkbox-mul input[type=checkbox] {
    height: 20px;
    width: 20px;
    margin-left: -10px;
    -webkit-appearance: checkbox;
    position: absolute;
    cursor: pointer;
}

.multisel_textbox .list-group {
    border: 1px solid rgba(0, 0, 0, .125);
    padding: 5px 0;
    position: absolute;
    width: 100%;
    z-index: 9999;
}

.multisel_textbox .list-group-item {
    padding: 5px 12px;
    border: 0px solid rgba(0, 0, 0, .125) !important;
}

.no-border {
    border: 1px solid #c0c0c0;
}

.custom-checkbox-mul .label-checkbox {
    margin: 0;
}

.custom-checkbox-mul .label-check {
    margin-left: 30px;
    cursor: pointer;
    color: #212529;
}

.form-group.multiselect {
    margin-bottom: 5px !important;
    border-top: 0;
    border-left: 0;
    border-right: 0;
}

.border-4px {
    border-radius: 4px;
}

.border-test {
    border: 1px solid #EC5F60 !important;
}

.border-none {
    border: none !important;
}

.multisel_textbox .input-error.multiselect {
    border-color: #EC5F60 !important;
}

.filter-block .multisel_textbox .list-group {
    position: relative !important;
}

.read-only {
    background-color: #ECECEC !important;
}

.width-5-perc {
    width: 5% !important;
}

.width-10-perc {
    width: 10% !important;
}

.width-15-perc {
    width: 15% !important;
}

.width-20-perc {
    width: 20% !important;
}

.width-25-perc {
    width: 25% !important;
}

.width-30-perc {
    width: 30% !important;
}

.width-35-perc {
    width: 35% !important;
}

.width-40-perc {
    width: 40% !important;
}

.width-45-perc {
    width: 45% !important;
}

.width-48-perc {
    width: 48% !important;
}

.width-50-perc {
    width: 50% !important;
}

.width-55-perc {
    width: 55% !important;
}

.width-60-perc {
    width: 60% !important;
}

.width-65-perc {
    width: 65% !important;
}

.width-75-perc {
    min-width: 75% !important;
}

.width-80-perc {
    min-width: 80% !important;
}

.width-90-perc {
    width: 90% !important;
}

.width-95-perc {
    width: 95% !important;
}

.width-100-perc {
    width: 100% !important;
}

.width-20 {
    min-width: 20px !important;
    max-width: 20px !important;
}

.width-25 {
    min-width: 25px !important;
    max-width: 25px !important;
}

.width-30 {
    min-width: 30px !important;
    max-width: 30px !important;
}

.width-50 {
    min-width: 50px !important;
    max-width: 50px !important;
}

.width-60 {
    min-width: 60px !important;
    max-width: 60px !important;
}

.width-75 {
    min-width: 75px !important;
}

.width-75-only {
    min-width: 75px !important;
    max-width: 75px !important;
}

.div-float-left {
    float: left;
}

.div-float-right {
    float: right;
}

.small-text-11 {
    font-size: 11px;
    color: #5b5b5b;
}

.height-100-perc {
    height: 100% !important;
}

.height-250 {
    min-height: 250px !important;
}

.sizeChecbox {
    height: 18px !important;
    width: 18px !important;
    background-color: 1px solid #C0C0C0;
}

.height-75 {
    min-height: 75px !important;
}

.normal-text {
    color: #212529 !important;
}

.card-add-div {
    width: 220px;
    height: 136px;
    align-items: center;  
    margin-bottom: 15px;
}

.card-wh {
    width: 74px;
    height: 50px;
    object-fit: contain; 
}

.margin-rightM3 {
    margin-right: -3px !important;
}

.margin-rightM5 {
    margin-right: -5px !important;
}

.margin-rightM8 {
    margin-right: -8px !important;
}

.margin-rightM10 {
    margin-right: -10px !important;
}

.margin-right0 {
    margin-right: 0px !important;
}

.margin-right1 {
    margin-right: 1px !important;
}

.margin-right2 {
    margin-right: 2px !important;
}

.margin-right3 {
    margin-right: 3px !important;
}

.margin-right4 {
    margin-right: 4px !important;
}

.margin-right5 {
    margin-right: 5px !important;
}

.margin-right7 {
    margin-right: 7px !important;
}

.margin-right8 {
    margin-right: 8px !important;
}

.margin-right10 {
    margin-right: 10px !important;
}

.margin-right15 {
    margin-right: 15px !important;
}

.margin-right16 {
    margin-right: 16px !important;
}

.margin-right17 {
    margin-right: 17px !important;
}


.margin-right20 {
    margin-right: 20px !important;
}

.margin-right23 {
    margin-right: 23px !important;
}

.margin-right25 {
    margin-right: 25px !important;
}

.margin-right27 {
    margin-right: 27px !important;
}

.margin-right30{
    margin-right: 30px !important;
}

.margin-right35{
    margin-right: 35px !important;
}

.margin-right40 {
    margin-right: 40px !important;
}

.margin-right50 {
    margin-right: 50px !important;
}

.margin-right75 {
    margin-right: 75px !important;
}

.margin-right100 {
    margin-right: 100px !important;
}

.margin-rightM20 {
    margin-right: -20px !important;
}

.margin-leftM5 {
    margin-left: -5px !important;
}

.margin-leftM7 {
    margin-left: -7px !important;
}

.margin-leftM10 {
    margin-left: -10px !important;
}

.margin-leftM15 {
    margin-left: -15px !important;
}

.margin-left0 {
    margin-left: 0px !important;
}

.margin-left1 {
    margin-left: 1px !important;
}

.margin-left2 {
    margin-left: 2px !important;
}

.margin-left3 {
    margin-left: 3px !important;
}

.margin-left5 {
    margin-left: 5px !important;
}

.margin-left6 {
    margin-left: 6px !important;
}

.margin-left8 {
    margin-left: 8px !important;
}

.margin-left10 {
    margin-left: 10px !important;
}

.margin-left11 {
    margin-left: 11px !important;
}

.margin-left12 {
    margin-left: 12px !important;
}

.margin-left15 {
    margin-left: 15px !important;
}
.margin-left-15 {
    margin-left: -15px !important;
}

.margin-left17 {
    margin-left: 17px !important;
}

.margin-left20 {
    margin-left: 20px !important;
}

.margin-left30 {
    margin-left: 30px !important;
}

.margin-left40 {
    margin-left: 40px !important;
}

.margin-left50 {
    margin-left: 50px !important;
}

.margin-left80 {
    margin-left: 80px !important;
}

.margin-bottom-3 {
    margin-bottom: -3px !important;
}

.margin-bottom0 {
    margin-bottom: 0px !important;
}

.margin-bottom2 {
    margin-bottom: 2px !important;
}

.margin-bottom3 {
    margin-bottom: 3px !important;
}

.margin-bottom5 {
    margin-bottom: 5px !important;
}

.margin-bottom7 {
    margin-bottom: 7px !important;
}

.margin-bottom8 {
    margin-bottom: 8px !important;
}

.margin-bottom10 {
    margin-bottom: 10px !important;
}

.margin-bottom12 {
    margin-bottom: 12px !important;
}

.margin-bottom15 {
    margin-bottom: 15px !important;
}

.margin-top-m7 {
    margin-top: -7px !important;
}

.margin-top-m6 {
    margin-top: -6px !important;
}

.margin-top-m5 {
    margin-top: -5px !important;
}

.margin-top-m3 {
    margin-top: -3px !important;
}

.margin-top-m2 {
    margin-top: -2px !important;
}

.margin-top-m10 {
    margin-top: -10px !important;
}

.margin-top-m15 {
    margin-top: -15px !important;
}
.margin-top-m35 {
    margin-top: -35px !important;
}
.margin-top-m40 {
    margin-top: 40px !important;
}

.autocomplete-dropdown-container {
    margin-top: 1px;
    z-index: 9999 !important;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    color: #212529 !important;
}

.loc-item,
.loading-loc {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.hide {
    display: none !important;
}

.hidden {
    display: none !important;
}

.input-content-box {
    min-width: 5rem;
    max-width: 17rem;
    width: 100%;
    margin-left: 15px;
}

.justify-space-between {
    display: flex;
    justify-content: space-between;
}

.justify-normal {
    display: flex;
    justify-content: normal;
}

.fontSize {
    font-size: 20px;
}

.fontSize10 {
    font-size: 10px !important;
}

.fontSize12 {
    font-size: 12px !important;
}

.fontSize13 {
    font-size: 13px;
}

.fontSize14 {
    font-size: 14px !important;
}

.fontSize15 {
    font-size: 15px;
}

.fontSize16 {
    font-size: 16px !important;
}

.fontSize18 {
    font-size: 18px !important;
}
.templateIconBtn_close {
    position: absolute !important;
    top: 50px;
    right: 9px;
    z-index: 10;
}
.insuranceIconBtn_close {
    position: absolute !important;
    bottom: 7px;
    right: 3px;
    z-index: 10;
}

.btn-default {
    background-color: #fff !important;
    border: 1px solid #c0c0c0 !important;
    font-size: 1rem !important;
    padding: 0.4rem;
    border-radius: 4px !important;
}

.btn-default:active {
    box-shadow: 0px 2px 2px #000;
    color: #ffffff !important;
    background-color: #1c3664 !important;
}

.dropdown-menu ul li a {
    text-decoration: none;
    z-index: 10001;
}

.dropdown-menu ul li a:hover {
    text-decoration: none;
    z-index: 10001;
    color: #ffffff;
    background-color: #1074B9;
}

i.closeIconCommonTab {
    color: #a1a8ae !important;
    font-size: 13px;
    margin-right: 0;
    cursor: pointer;
}
.closeIconCommonTab i {
    color: #a1a8ae !important;
    font-size: 13px;
    margin-right: 0;
    cursor: pointer;
}

i.closeIconCommonTab:hover {
    color: #000 !important;
    box-shadow: 1px #c0c0c0;
    cursor: pointer;
}
.closeIconCommonTab:hover i {
    color: #000 !important;
    box-shadow: 1px #c0c0c0;
    cursor: pointer;
}

.closeIconCommonTab:hover i {
    color: #000 !important;
    box-shadow: 1px #c0c0c0;
    cursor: pointer;
}

.closeIconForTab i {
    color: #a9a9a9;
    font-size: 84%;
    margin-left: 7px;
}

.closeIconForTab:hover i {
    color: #EC5F60;
    box-shadow: 1px #c0c0c0;
}

.padding-left-10 {
    padding-left: 10px !important;
}

.chip-label-mui {
    font-weight: 600;
    border-radius: 10px !important;
    background-color: #e0e0e0 !important;
}

.img-avatar {
    margin-right: 1em;
    display: inline-block;
    font-size: 12px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    vertical-align: middle;
    border-width: 0px;
    border-style: solid;
    text-align: center;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: 10px;
}

img.profile {
    max-width: 90% !important;
    max-height: 100% !important;
}

.avatar-text {
    padding-left: 10px;
    color: #fff;
}

.select-label {
    margin-top: -5px !important;
    margin-bottom: 4px !important;
}

.width-5 {
    min-width: 5px !important;
    max-width: 5px !important;
}

.width-100 {
    min-width: 100px !important;
}

.width-150 {
    min-width: 150px !important;
}

.width-200 {
    min-width: 200px !important;
}

.width-100px {
    width: 100px !important;
}

.width-200px {
    width: 200px !important;
}

.width-350px {
    min-width: 250px !important;
}

.max-width-40px {
    min-width: 40px !important;
    max-width: 40px !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.margin-top67 {
    margin-top: 67px;
}

.margin-top1 {
    margin-top: 1px !important;
}

.margin-top2 {
    margin-top: 2px !important;
}

.margin-top3 {
    margin-top: 3px !important;
}

.margin-top4 {
    margin-top: 4px !important;
}

.margin-top5 {
    margin-top: 5px !important;
}

.margin-top6 {
    margin-top: 6px !important;
}

.margin-top7 {
    margin-top: 7px !important;
}

.margin-top10 {
    margin-top: 10px !important;
}

.margin-top11 {
    margin-top: 11px !important;
}

.margin-top12 {
    margin-top: 12px !important;
}

.margin-top16 {
    margin-top: 16px !important;
}

.margin-top_16 {
    margin-top: -16px !important;
}

.margin-top18 {
    margin-top: 18px !important;
}

.margin-top22 {
    margin-top: 22px !important;
}

.margin-top25 {
    margin-top: 25px !important;
}

.margin-top55 {
    margin-top: 55px;
}

.MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(0.9) !important;
    transform-origin: top left;
}

.MuiAutocomplete-input {
    opacity: 0;
    flex-grow: 1;
    height: 42px !important;
    text-overflow: ellipsis;
}

.multiselect.btn-default {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
}

.multiselect:focus.multiselect {
    border-color: #ced4da !important;
    background-color: #f0f0f0 !important;
}

.Mui-focused > .MuiSelect-select:focus,
.MuiSelect-select:focus {
    background-color: transparent !important;
}

.MuiGrid-root .form-group .MuiTextField-root .MuiInputLabel-root {
    line-height: 22px !important;
}

.MuiGrid-root .form-group .MuiTextField-root .MuiInputLabel-shrink {
    line-height: 12px !important;
}
.patient-list-tab-xs-9
{
    flex-grow: 0;
    max-width: 60% !important;
    flex-basis: 60% !important
}

.absolute {
    position: absolute !important;
}
.relative {
    position: relative !important;
}

.subTab .nav-item .nav-link.active {
    background: #1074B9;
    color: #fff;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-left: 10px;
    margin-right: 4px;
    border: 1px solid #1074B9;
}

.subTab .nav-item .nav-link:hover {
    background: #1074B9;
    color: #fff;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-left: 10px;
    margin-right: 4px;
    border: 1px solid #1074B9;
}

.subTab .nav-item .nav-link:hover .closeIconCommonTab i {
    background: #1074B9;
    color: #fff;
}

.subTab .nav-item .nav-link.active .closeIconCommonTab i {
    background: #1074B9;
    color: #fff;
}

.subTab.nav-pills {
    /* background-color: #f5f5f5; */
    border-bottom: 1px solid #1074B9;
}

.nav-pills .nav-link span i.fa-plus {
    color: #86888a;
    font-size: 12px;
}

.nav-pills .nav-link:hover span i.fa-plus {
    color: #fff;
    font-size: 12px;
}

.nav-pills .nav-link.active span i.fa-plus {
    color: #fff;
    font-size: 12px;
}

/*multiselect*/

.MuiSelect-select.MuiSelect-select {
    /* padding-right: 24px; */
    line-height: 2;
    font-weight: 400;
}

.MuiMenuItem-root{
    font-weight: 600;
    font-family: 'Lato';
    font-size: 10px;
}

.warning_lock {
    text-align: center;
    background-color: #FFD6CA;
    padding: 10px 10px;
    color: #DF3C3C;
    border-radius: 3px;
    display:block !important;
    width: 100% !important;
}

.table-icon {
    color: #212529 !important;
}

.table-row-height {
    height: 46px !important;
}

.download-dropdown {
    font-size: 14px !important;
    min-width: 150px !important;
    margin-top: 0px !important;
    margin-left: -55px !important;
    border-bottom: none !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.download-dropdown-menu-item {
    padding: 7px !important;
    min-width: 145px !important;
    text-decoration: none !important;
    border-bottom: 1px solid #EBECEC;
}

.download-dropdown-menu-item :hover {
    /* color: #1c3664;  */
    background: #e9ecef !important;
    text-decoration: none;
    color: #16181b !important;
}

.text-dec-none {
    text-decoration: none !important;
    color: black;
}

.leftDiv {
    width: 75%;
    float: left;
}

.rightDiv {
    width: 25%;
    float: right !important;
}

.exclamationIcon {
    font-size: 12px;
    color: red;
}

th {
    text-align: center !important;
}

.width-125px {
    width: 125px !important;
}

.width-130px {
    width: 130px;
}

.width-135px {
    width: 135px;
}

.width-140px {
    width: 140px;
}

.width-145px {
    width: 145px;
}

/* for type head dropdown width */
div[aria-label="menu-options"] {
    width: fit-content !important;
}

/* font size */
thead {
    z-index: 9;
}

.dropdown-checkbox {
    padding: 2px;
    /* border: 1px solid #000; */
}

@media print {
    body * {
        visibility: hidden;
    }

    .visibility_none * {
        visibility: hidden !important;
    }

    #section-to-print,
    #section-to-print * {
        visibility: visible;
    }

    #section-to-print {
        position: absolute;
        left: 0;
        top: 0;
    }
}

#tick-mark {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
}

#tick-mark::before {
    position: absolute;
    left: 0;
    top: 50%;
    height: 50%;
    width: 3px;
    background-color: #336699;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}

#tick-mark::after {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    background-color: #336699;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}

.small_width {
    width: 40px !important;
    height: 40px !important;
}

.d-contents {
    display: contents !important;
}

/* .MuiSvgIcon-root{
    position: relative !important;
    top: 6px !important;
} */

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
    position: relative !important;
    /* align-content: center; */
    /* align-items: center; */
    top: 8px !important;
}

.border_radius14px {
    border-radius: 14px !important;
}

.MuiPaper-root.MuiMenu-paper.MultiSelect-Paper.MuiPopover-paper.MuiPaper-rounded {
    overflow: hidden !important;
}


.MuiIconButton-root {
    padding: 0px !important;
}

/* for select input arrow */
.MuiInputBase-root.MuiInput-root.MuiInput-underline.date-filter-select.MuiInputBase-formControl.MuiInput-formControl .MuiSvgIcon-root.MuiSelect-icon {
    position: absolute !important;
    top: 5px !important;
}

/* for mutiselect in my profile */
.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
    position: relative !important;
    top: 2px !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconSmall.MuiChip-deleteIconColorDefault.MuiChip-deleteIconOutlinedColorDefault {
    top: 0px !important;
}

.curve_bottom {
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
}

.MuiGrid-root.MuiGrid-container .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
    padding-right: 0px !important;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
    padding-right: 12px !important;
}

/* div[variant="outlined"].MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl{
    margin-top: 13px !important;
}
div[variant="outlined"].MuiOutlinedInput-root.MuiInputBase-root.Mui-focused.MuiInputBase-colorPrimary.MuiInputBase-formControl{
    margin-top: 16px !important;
    height: 32px !important;
    background-color: #f0f0f0 !important;
} */
.taskIconbtn_close {
    position: absolute !important;
    top:28px;
    right: 13px;
}
.profileIconBtn_close {
    position: absolute !important;
    top: 32px;
    right: 0px;
    z-index: 10;
}

.profileCloseIcon {
    cursor: pointer !important;
    padding: 0 10px;
    position: absolute;
    right: -11px;
    top: -5px;
}
.reportsIconbtn_close {
    position: absolute !important;
    right: 0px;
    top: 11px;
}
.advSearchIcon_close {
    position: absolute !important;
    bottom: 11px;
    right: 2px;
}
.pagelockSearchIcon_close {
    position: absolute !important;
    bottom: 21px;
    right: 15px;
}

.profileCloseIcon>i.fa-times {
    font-size: 16px;
    color: #000 !important;
}

.form-control:focus {
    box-shadow: none !important;
}

.iconSpan i {
    font-size: 25px;
}
.tab{
    font-weight: bold !important;
    max-width: fit-content !important;
    text-transform: none !important;
    font-size: 14px !important;
    padding: 0 20px !important;

    /* border: 1px solid red !important; */
}

.tabMainMenu{
    font-weight: bold !important;
    max-width: fit-content !important;
    text-transform: none !important;
    font-size: 14px !important;
    padding: 0 20px !important;
    border: 2px solid #eeeeee!important;
    background-color: white !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-color: #eeeeee !important;
}

.tabMainMenu:first-child {
    border-left: none !important;
}

.gridBackgroundColor{
    padding-left: 15px;
    background-color: #ffffff;
}

.tab:hover{
    background: #f0f3f6 !important;
    text-decoration: none;
    /* color: #16181b !important; */
    /* border-top-left-radius: 21px;
    border-top-right-radius: 21px; */
}
.Mui-selected.tab {
    font-weight: normal !important;
    font-family: 'Lato';
}
.MuiTabs-indicator{
    height: 3px !important;
    background-color: #1479BB !important;
    
}

.common-search-wrapper-style {
    display: flex !important;
    justify-content: normal !important;
    padding: 1px 20px 20px 20px !important;
    border-radius: 8px !important;
    border: 1px solid #dddd;
    background-color: #fbfbfb;
}
.patientslist-patient-search {
    display: flex !important;
    justify-content: normal !important;
    border-radius: 8px !important;
    border: 1px solid #dddd;
    background-color: #fbfbfb;
    padding-bottom: 26px;
}
.patient-ledger-search {
    display: flex !important;
    justify-content: normal !important;
    padding: 0px 6px 24px 6px;
    border-radius: 8px !important;
    border: 1px solid #dddd;
    background-color: #fbfbfb;
}
.standard_fee_search {
    padding: 20px 20px !important;
    border-radius: 14px !important;
    border: 1px solid #dddd;
    background-color: #fbfbfb;
}
.MuiBox-root.css-1gsv261{
    border-bottom: 0px !important;
}
.searchBox {
    background: #fbfbfb !important;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 9%);
    border-radius: 2px;
    padding: 30px !important;
    height: 100% !important;
    border: 1px solid #dddd;
    border-radius: 15px !important;
}

.searchBoxTab {
    padding-top: 5px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
}

.MuiBox-root {
    margin-bottom: 0x !important;
}

.positionSvgOfMaterialMultiSelelct {
    position: absolute;
    bottom: 8px;
    right: 5px;
}

.min-h-full{
    min-height: 100vh !important;
}

.min-h-35{
    min-height: 35px !important;
}

/* table tr td :nth-child(2){
 text-align: center;
border: 3px solid red;} */

/* .roles-permission-inner {
    margin-left: 5px;
} */
.roles-permission-font {
    font-family: "Roboto","Helvetica","Arial",sans-serif !important ;
    font-weight: 500;
}
.roles-permission-header {
    margin-left: 5px;
    width: 49vw;
    display: flex;
    justify-content: space-between;
}

.MuiListItem-root,
.MuiTypography-root,
.MuiMenuItem-root,
.MuiInputBase-input,
.MuiInput-input,
.MuiTypography-body1,
.MuiSelect-root {
    font-family: 'Lato', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
}

.paymentFormText{
    font-size: 13px !important;
    color: #757575 !important;
    font-family: 'Lato';
}

.paymentFormValueText{
 font-size: 13px !important;
 color: #000;
 font-weight: 500;
 font-family: 'Lato';   
}

.postPayment{
    margin-left: -24px;
    margin-right: -44px;
}

.postPaymentTable{
    border-collapse: collapse;
    border: none !important;
}

.postPaymentTable tr td{
    border:none !important;
}

.postPaymentTable tr{
    border:none !important;
}

.eraPaymentTable{
    border-collapse: collapse;
    border: none !important;
}

.eraPaymentTable tr td{
    border:none !important;
}

.eraPaymentTable tr{
    border:none !important;
    height: 10px !important;
}
.boxIcon{
    display: flex!important;
    background-color: #f5f5f5 !important;
    padding: 3px !important;
    margin-left: 10px;
    border-radius: 4px;
    border:1px solid #E0E0E0
}

.border-radius {
    border-radius: 8px !important;
    border-top-left-radius: 0 !important;
}

.border-radius-8 {
    border-radius: 8px !important;
}

.border-radius-15 {
    border-radius: 15px !important;
}

.insurancePayment{
    margin-left: 20px;
    margin-right: 20px;
}

.patientPayment{
    margin-left: 20px;
    margin-right: -6px;
}

.warningPopUp{
    background-color: #FFF2CA;
    color: #000;
    font-family: 'Lato';
    text-align: center;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.warningPopUp:hover{
    top: 0;      
}

.warningPopUpDiv{
    background-color: #FFF6DC;
    transition: top 1s;
    border-radius: 5px;
}

.Embed-container {
    height: 90vh; 
    width: 100%;
    border: none; 
    border-radius: 8px; 
}

.Embed-container iframe {
    border: none !important;
    border-radius: 8px;
}

.helper-text-box{
    padding: 10px;
    background-color: #edebeb;
    border-radius: 5px;
    color: #7d7d7d;
    font-size: 13px;
    font-family: 'Lato';
}

.helper-text-box ul{
    margin-left: 10px;
}

.helper-text-box ul li{
    margin-top: 10px;
}

.custom-icon-date {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f8192"><path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    margin-top: 4px;
    display: inline-block;
}


.custom-icon-reset {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236f8192"><path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/></svg>');
    background-size: contain;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    margin-top: 4px;
    display: inline-block;
    filter: invert(48%) sepia(13%) saturate(657%) hue-rotate(166deg) brightness(94%) contrast(90%);
}

/* App.css */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.disabled-tab {
    cursor: not-allowed !important; 
    opacity: 0.5;       
}

.insurance-data {
    border: 1px solid;
}

.insurance-data span {
    color: black;
}

.insurance-data .row {
    min-height: 25px;
    font-size: 15px;
}

.insurance-data .value {
    text-align: right;
    font-weight: bold;
}

#form_patient_info .button {
    font-size: 13px;
    color: #1074b9;
    cursor: pointer;
    font-weight: bold;
}

#form_patient_info .button-normal {
    font-size: 13px;
    color: #1074b9;
    cursor: pointer;
}

#uitable>table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 12px;
}

#uitable td, #uitable th {
    border: 1px solid #dddddd !important;
    text-align: left;
    padding: 8px;
}

#uitable tr:nth-child(odd) {
    background-color: #eff1f1;
}

.danger {
    color: #da1254;
}

.imageCardMainRow {
    height: 100% !important;
}

/* .imageCardRow {
    width: 100% !important;
    height: 100% !important;
} */

.imgCardRow {
    background: var(--Grey-G100, #F5F5F5);
}

.insurance-type {
    color: var(--Grey-G800, #424242);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.05px;
}
.insurance-name {
    color: var(--Grey-G600, #757575);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.05px;
}

.subscriber-name {
    color: var(--Grey-G900, #212121);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.05px;
}

.policy-data-td {
    height: 32px !important; 
    padding-left: 24px
}

.insurance-card-bg {
    background: var(--Grey-G100, #F5F5F5); 
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    width: 244px;
    height: 194px;
}

.profile_card:hover {
    filter: blur(2px);
}

.imageCardColbkup {
    height: 100% !important;
    padding: 10px !important;
    margin-right: 15px;
}

.imageCardCol {
    height: 100% !important;
}

.profile_card {
    height: 100% !important;
    max-height: 200px;
}

#blue i {
    color: #1074b9;
    font-size: large;
}

.profile_card_pastcoverage {
    width: 100%;
    min-width: 10px;
    max-width: 200px;
}

.insurance_cards {
    padding-top: 5px;
    height: 225px;
}

.labelSubsriber {
    color: #212529;
    font-weight: bold;
}

.card-image input {
    display: none;
}

.card-image label i {
    font-size: 1.3rem;
    position: absolute;
    top: 7px;
    color: black;
    left: 6px;
    z-index: 99;
}

.patientsTabs {
    padding-left: 10px !important;
}

.patientsTabContent {
    margin-top: 0px !important;
}

.patientAddEdit {
    padding-left: 0px;
}

.patientName {
    cursor: pointer;
    color: #005CB9;
}

.patientName:hover {
    text-decoration: underline;
}

.searchValue {
    padding-top: 10px;
    padding-left: 8px;
}

.min-height-500 {
    min-height: 500px !important;
}

.top-14 {
    top: 14px !important;
}

.tab-heading {
    cursor: pointer;
    color: #005CB9;
    z-index: 7;
    position: relative;
}

.width-95-per {
    width: 95% !important;
}

.width-60-per {
    width: 60% !important;
}

.width-70-per {
    width: 70% !important;
}

.profile_card_insurance_add {
    max-height: 150px;
    cursor: pointer;
    opacity: 0.35;
    transition: .45s;
}

.profile_card_insurance_add:hover {
    opacity: 1;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
}

.profile_card_insurance {
    max-height: 150px;
    cursor: pointer;
    opacity: 1;
    transition: .45s;
}

.profile_card_insurance:hover {
    opacity: 0.6;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
}

.add-card-overlay {
    float: left;
    z-index: 3;
    cursor: pointer;
    background-color: aqua;
    border-color: brown;
    border: brown 1px solid;
}

.closeIconForTab {
    cursor: pointer;
}

.sub-heading-insurance {
    padding-top: 2px;
}

.text-heading-insurance {
    font-weight: bold;
    font-size: 14px;
}

.input-responsible-family {
    min-width: 5rem;
    max-width: 17rem;
    width: 100%;
}

.button-add-family {
    min-width: 25px !important;
    max-width: 25px !important;
    padding-left: 8px !important;
    padding-right: 25px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.td-v-align {
    vertical-align: middle !important;
}

.warning-patient-div {
    margin-top: 14px !important;
    margin-left: 0px !important;
    margin-bottom: 8px !important;
}

.bold {
    font-size: 14px !important;
    font-size: bold;
}

.spanSmall {
    font-size: 12px !important;
    font-size: small;
}

.insurance_names {
    position: relative;
    top: -42px;
    left: -11px;
}

.insurance_names label {
    padding: 0 5px;
    background-color: #fff;
    color: #1c3664;
    font-weight: bold;
}
.insuranceinfo_names {
    padding-left: 0px; 
}

.insuranceinfo_namesBkup {
    position: relative;
    top: -42px;
    left: -11px;
}

.insuranceinfo_names label {
    padding: 0 5px;
    background-color: #fff;
    color: #1c3664;
    font-weight: bold;
    margin-left: 6px;
}
.font-600 {
    font-weight: 600px;
}

.insurance_body{
    margin-top: -10px !important;
    margin-bottom: 20px !important;
}

.insurance_functional_links a {
    padding: 0;
}

.insurance_table {
    padding: 0%;
}

.lh-1_5{
    line-height: 1.5 !important;
}

.patient-dob-search-calendar {
    padding-top: 17px;
}

.date-filter-select  {
    margin-top: 0px !important;
}

.add-new-disabled  {
    color: #bbbbbb !important;
}

.closeIconForImage{
    font-size: 14px !important;
    color: #000;
    font-weight: bold !important;
    cursor: pointer;
}
.closeIconForImage:hover{
    color: #EC5F60;
}

.dropdown-menu.download-dropdown li:hover{
    background: #e9ecef !important;
    text-decoration: none;
    color: #16181b !important;
}
.dropdown-menu.download-dropdown li:hover a{
    color: #16181b !important;
}

div.patientListIcons{
    position: absolute;
    top: 143px;
    right: 68px;
}
.td-center {
    text-align: center !important;
}

.edit-alert-dropdownMenu{
    margin-top: 5px !important ;
    height: 15px !important;
    padding: 15px !important;
}
.ml-8 {
    margin-left: -8px !important;
}
.ml-10 {
    margin-left: -10px !important;
}

.option-popup-width{
    width:190px
}
.option-popup-width::before{
   left: 160px !important;
}
.margin-left-25 {
    margin-left: -25px !important;
}

.insurance-panel-box {
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top: 30px !important;
    padding-bottom: 0px !important;
    height: 100% !important;
    border-radius: 15px !important;
}

.insurance-panel-box-without-pl {
    padding-right: 30px !important;
    padding-top: 30px !important;
    padding-bottom: 0px !important;
    height: 100% !important;
    border-radius: 15px !important;
}
.insurance-panel-box-without-plr {
    padding-right: 0px !important;
    padding-top: 30px !important;
    padding-bottom: 0px !important;
    height: 100% !important;
    border-radius: 15px !important;
}
.mt-5-copy {
    margin-top: 2rem!important;
    margin-left: -5px !important;
} 

.editButtonRow {
    position: relative;
    bottom: 25px;
    left: -43px;
}
.rbt-menu.dropdown-menu.show {
    max-height: 400px !important;
}
.pr-pl-10 {
    padding: 0px 10px;
}
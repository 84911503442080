.task_linkBox_head{
    background-color: #F9FAFB;
    padding: 5px;
    color: #212529;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #f6f0f0;
    border-bottom: 2px solid #dee2e6;
}
.task_linkBox_head a{
    padding: 0;
    color: #212529;
}
.task_link_box_body{
    display: flex;
    justify-content: space-between;
    padding: 5px;
    border: 1px solid #e0e0e0;
}
.task_link_box_body label{
    cursor: pointer;
}
.task_link_box_body i{
    cursor: pointer;
    font-size: 12px !important;
}
.task_link_box_body label i{
    padding-right: 5px;
}

.searchNpi{
	position: inherit;
    z-index: 2;
    cursor: pointer;
}
.searchNpi:hover{
	text-decoration: underline;
  	color: #005CB9;
}

.div-text-line {
	float: left;
	clear: none; 
  }
  .div-h-line-95 {
	border-bottom: 1px solid #C0C0C0 !important;
	float: left !important;
	width: 95%; 
  }
  .div-h-line-79 {
	border-bottom: 1px solid #C0C0C0 !important;
	float: left !important;
	width: 79%; 
	padding-top: 5px !important; 
	padding-left: 3px !important;
	margin-left: 3px !important;
  }
  .div-inline {
	float: left !important;
	display: inline !important;
	padding-top: 5px; 
	padding-left: 3px;
	padding-right: 5px;
	color: #1c3664;
	font-weight: bold;
  }

  .div-inline-normal {
	float: left !important;
	display: inline !important;
	padding-top: 5px; 
	padding-left: 20px !important;
	padding-right: 5px;
  }

  .div-inline-space {
	float: left !important;
	display: inline !important;
	padding-left: 5px !important;
  }

.imgCursor {
	cursor: pointer;
}

.practice.imgCursor{
	position: relative;
    bottom: 7px;
    left: 130px;
}
.uitable{
    margin-right: 0px !important;
    margin-left: 0px !important;
    padding: 7px 0px;
}

.uitableRemovePadT{
    padding-top: 0px !important;
}

.uitableRemovePadB{
    padding-bottom: 0px !important;
}

.uitable > table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.uitable td,
.uitable th {
    border-bottom: 1px solid #d0cece;
    text-align: left;
    padding: 8px;
}

.uitable .bold{
    font-weight: bold;
}

.uitable .align-right{
    text-align: right;
}

.uitable .align-center{
    text-align: center;
}

.tableRow{
    margin-right: 0px !important;
    margin-left: 0px !important;
    padding: 15px;
}

.tableRow td,
.tableRow th {
    border: 1px solid #d0cece;
    text-align: left;
    padding: 8px;
}

.innerColSpan{
    padding: 0px !important;
}

.innerTable{
    width: 100%;
}

.patientsTabContent table{
    width: 100%;
}
.header-text {
    font-weight: bold !important;
    font-size: large !important;
    padding-top: 5px;
    margin-left: -15Px !important;
}
.header-sub-text {
    font-weight: bold !important;
    padding-top: 10px !important;
    margin-left: 15Px !important;
}
.div-full {
    display: inline-block !important;
}
.div-temp-border {
    border: solid 1px #d00ece;
}
.margin-left-24 {
    margin-left: -24px !important;
}
.align-right {
    display: flex;
    justify-content: flex-end;
}
.patient-ledger-box {
    padding: 10px 25px 10px 25px;
    background-color: #fff;
    border-radius: 8px;
    margin-left: 30px;
    margin-right: 30px;
}
.styled-grid {
    color: '#333';
    padding-top: 24px;
    padding-left: 28px;
    padding-bottom: 13px;
    border: 1px solid #ddd;

}
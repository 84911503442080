.green-header{
    background-color: #147d1b;
    color: #f0f5f0;
}
.blue-header{
    background-color: #203b69;
    color: #f0f5f0;
}
.red-light-header{
    background-color: #ffe9e9;
    border: 1px solid #e8c5c5;
    color: #cf0000;
}
.brown-header{
    background-color: #a14573;
    color: #f0f5f0;
}
.red-header{
    background-color: #c53d2b;
    color: #f0f5f0;
}
.orange-header{
    background-color: #c7700d;
    color: #f0f5f0;
}

.eligibility-search-box,
.batch-eligibility-box {
    background: #ffffff !important;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 9%);
    padding: 30px !important;
    height: 100% !important;
    border-radius: 15px !important;
    border-top-left-radius: 0 !important;
}

.eligibility-search-box-tab {
    padding-top: 0 !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
}


textarea
{
  border:1px solid #ced4da !important;
  border-radius: 8 !important;
  width:100%;
  margin:0px 0;
  padding:3px;
  background-color: #eee;
}

.form-group textarea{
  border-radius: 8px;
}
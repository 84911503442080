.content-section{
	min-width: 100%!important;
}

.modal-dialog{
	min-width: 80%!important;	
}


/* #demo-multiple-checkbox~input.MuiSelect-nativeInput {
    display: none !important;
} */

ul {
  list-style-type: none;
  padding: 0;
}

.parent {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.child {
  margin-left: 20px;
}

.child ul {
  margin-top: 5x;
  margin-left: 5px;
}

.child li {
  margin-left: 0; /* Reset margin for child li elements */
}



.parent li,
.child li {
  transition: background-color 0.3s ease;
}

.multi-select-search-li{
    cursor: pointer;
}

.multi-select-search-li:hover {
    background-color: #dbdfe2a8 !important;
    cursor: pointer;
}

.multi-select-search-li-selected {
    background-color: rgba(158,207,250,0.3);
    cursor: pointer;
}

/* 
.form-group div .MuiFormControl-root label[id="demo-multiple-checkbox-label"] {
    line-height: 15px !important;
    margin-left: -13px !important;
}

.form-group div .MuiFormControl-root label[id="demo-multiple-checkbox-label"].MuiInputLabel-shrink {
    line-height: 15px !important;
    margin-left: 0px !important;
}

.multi-select-search div.MuiAutocomplete-root div.MuiFormControl-root label {
    margin-left: 0px !important;
}

.multi-select-search div.MuiAutocomplete-root div.MuiFormControl-root input {
    height: 32px !important;
}

li.li-classname {
    padding : 0px !important;
    margin: 0px !important;
    line-height: 0px  !important;
}

.max-height-70 {
    max-height: 70px !important;
}

.max-width-1500 {
    max-width: 1500px !important;
}

.max-width-350 {
    max-width: 350px !important;
}

.demo-multiple-checkbox-label{
    transform: translate(4, 24px) scale(1) !important;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined {
    font-size: 21px !important;
    position: absolute !important;
    right: 0px !important;
}


.Mui-focused>.MuiSelect-select{
    background-color: #fff !important;
}

.multi-select-search-li{
    cursor: pointer;
}

.multi-select-search-li :hover {
    background-color: #dbdfe2a8;
    border-radius: 3px;
}

.multi-select-search-li-selected {
    background-color: rgba(158,207,250,0.3);
    border-radius: 3px;
    cursor: pointer;
}


.input-error fieldset {
    border-color: #EC5F60 !important;
} */

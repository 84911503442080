table thead tr th.small {
    width: 135px !important;
    font-weight: bold !important;
    font-size: 1rem !important;
    min-width: 125px !important;
}

table thead tr th.extra-small {
    width: 80px !important;
    font-weight: bold !important;
    font-size: 1rem !important;
    min-width: 80px !important;
}
table tbody tr td.small {
    width: 135px !important;
    font-size: 12px !important;
    min-width: 125px !important;
    word-wrap: break-word !important;
    word-break: break-word;
    white-space: normal !important; 
}

table tbody tr td.extra-small {
    width: 80px !important;
    font-size: 12px !important;
    min-width: 80px !important;
}
table thead tr th.large {
    min-width: 500px !important;
    max-width: 100% !important;
}
table tbody tr td.large {
    min-width: 500px !important;
    max-width: 150px !important;
    text-wrap: wrap !important;
}
table thead tr th.medium {
    min-width: 250px !important;
    /* max-width: 20% !important; */
}
table tbody tr td.medium {
    width: 250px !important;
    text-wrap: wrap !important;
    padding-left: 30px !important;
    /* max-width: 20% !important; */
}
table tbody tr td.check_box {
    width: 50px !important;
    max-width: 50px !important;
}
.sortUpArrow {
    cursor: pointer;
    width: 13px;
    position: absolute !important;
    top: 12px !important;
}

.row-bg-change-last-opened-indication {
    transition: background-color 0.5s ease;
    background-color: #E0F4FF !important;
}

.sortDownArrow {
    cursor: pointer;
    width: 13px;
    position: absolute;
    bottom: 13px !important;
}

.singleSortUpArrow {
    cursor: pointer;
    width: 13px;
    position: absolute;
    bottom: 25px;
}

.singleSortDownArrow {
    cursor: pointer;
    width: 13px;
    position: absolute;
    top: 23px;
}

/* resizing table */
.table.resize thead th {
    position: relative;
}

.resizer {
    /* Displayed at the right side of column */
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    cursor: col-resize;
    user-select: none;
}

.resizer:hover,
.resizing {
    border-right: 2px solid blue;
}

.large {
    max-width: 100% !important;
    /* width: 100% !important; */
}

.small {
    max-width: 20% !important;
}

.max-width_21percent {
    max-width: 21% !important;
    /* width: 125px !important; */
}

table tbody tr td .leftSide.dropdown-menu.show {
    position: absolute !important;
    will-change: transform !important;
    transform: translate3d(10px, 42px, 0px) !important;
    top: -18px;
    left: -100px !important;
    color: #000;
    box-shadow: -4px -3px 17px 0px rgba(106, 106, 106, 0.16) !important;
}

table tbody tr td .leftSide.dropdown-menu::before {
    content: "";
    width: 16px !important;
    height: 16px !important;
    background: #ffffff;
    position: absolute !important;
    top: -7px;
    z-index: -1 !important;
    right: 10px !important;
    left: auto !important;
    -webkit-transform: rotate(45deg) !important;
    transform: rotate(45deg) !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-right: none !important;
    border-bottom: none !important;
}

.action_column {
    max-width: 200px !important;
    width: 150px;
}


table thead {
    background: var(--grey-300, #E0E0E0);
    color: var(--grey-g-800, #424242);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* table thead tr:hover {
    background: rgba(36, 167, 224, 0.10) !important;
} */


tr {
    border-bottom: 1px solid var(--grey-300, #E0E0E0);
    height: 50px !important;
}

table td {
    color: var(--Grey-G800, #424242);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/* table tbody tr:nth-child(odd) {
    background: var(--grey-ff, #FFF);
}

table tbody tr:nth-child(even) {
    background: var(--grey-g-50, #FAFAFA);
} */

table tbody tr:nth-child(odd) {
    background: #FFF;
}

table tbody tr:nth-child(even) {
    background: #FFF;
}

table tbody tr td a {
    color: var(--primary-p-2, #1479BB);

}

.responsiblePayerSpan {
    text-decoration: none;
    color: var(--primary-p-2, #1479BB);
    cursor: pointer;
}

.anchor-like-span {
    cursor: pointer;
    color: var(--primary-p-2, #1479BB);
}

.anchor-like-span:hover {
    cursor: pointer;
    color: var(--primary-p-2, #1479BB);
}
.link-text-color {
    color: var(--primary-p-2, #1479BB);
}

.link-text-color :hover {
    color: var(--primary-p-2, #1479BB);
}

tr:has(.checkbox-true:checked),
tr:has(.checkbox-true:checked):hover {
    background: rgba(36, 167, 224, 0.10) !important;
}

table tbody tr td {
    border: none !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}

table thead tr th {
    text-align: center;
    vertical-align: middle !important;
}

.truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px; 
    display: inline-block;
    vertical-align: middle;
}

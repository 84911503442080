.tableFixHead {
    overflow: auto;
    height: 500px;
}

.tableFixHead thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: darkgray;
}


.tableGroupHeader {
    background-color: lightgray;
    color: white !important;
}

.expandButton {
    cursor: pointer;
    background-color: gray;
    color: white;
    padding: 0.3rem;
    border-radius: 1rem;
}
.typeHeadStyle {
    /* margin: 0px 0px;
    position: relative;
    margin-bottom: -30px; */
    max-height: 32px;
}

.typeHeadStyle label {
    position: relative;
    padding-left: 0px !important;
    top: -26px;
    cursor: text;
    font-size: 1rem;
    color: #0000008a;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
}

.typeHeadStyle .rbt input:focus+.typeHeadStyle label, .typeHeadStyle input:not(:placeholder-shown)+.typeHeadStyle label {
    opacity: 1;
    transform: scale(.9) translateY(-155%) translateX(0px);
    color: #0000008a;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    padding-left: 1px !important;
}

.typeHeadStyle input {
    width: 100%;
    padding-left: 1px !important;
}

.typeHeadStyle label, .typeHeadStyle input {
    transition: all 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.9;
    padding-left: 10px !important;
}

.curved-input {
    overflow: hidden;
    border-radius: 25px;
}

.form-control:focus {
    background-color: #fff !important;
    border-color: none !important;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.asyncTypeahead .dropdown-menu .dropdown-item:focus {
    background-color: red;
}
.rbt input {
    z-index: 5;
    border-top: 1px solid #ced4da !important;
    border-left:1px solid #ced4da !important;
    border-right: 1px solid #ced4da !important;
    border-bottom:1px solid #ced4da !important;
    border-radius: 4px !important;
    width: 100% !important;
    height: 36px !important;

}

.rbt input::placeholder {
    color: #a9a9a9 !important;
}

#text {
    z-index: 0;
}

.form-control:focus {
    border-bottom: 1px solid #ced4da !important;
}
.textid {
    transform: scale(1) translateX(0px) translateY(-29px) !important;
}

.rbt-aux button.rbt-close {
    color: #606162;
    cursor: pointer;
    z-index: 5;
}

.rbt-aux:hover button.rbt-close {
    color: rgb(24, 23, 23);
    cursor: pointer;
    z-index: 5;
}


.app-container {
  display: grid;
  grid-template-columns: 15% 85%;
  grid-template-rows: auto 1fr;
  grid-template-areas: 
    "sidebar header"
    "sidebar content";
  height: 100vh;
}

headerComponent_Header {
  grid-area: header;
}

leftSideBarComponent_LeftSideBar {
  grid-area: sidebar;
  height: 100%;
}

.content-section {
  grid-area: content;
}
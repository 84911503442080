.permissions-table {
    width: 100%;
    border-collapse: collapse;
}

.permissions-table tr:not(:first-child):hover {
    background-color: #fbfbfb !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.1) !important;
    transition: box-shadow 0.3s ease !important;
}

.permissions-table tr:not(:first-child) {
    background-color: #ffffff !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.05) !important;
    transition: box-shadow 0.3s ease !important;
}


.permissions-table thead {
    background-color: #F9FAFB;
    border: 1px solid #ddd !important;
    position: sticky;
    top: 0;
    z-index: 1;
}

.custom-label {
    border: 1px solid transparent;
    border-radius: 3px;
    transition: border-color 0.3s ease, transform 0.3s ease;
}

.custom-label:not(.custom-label-no-hover):hover {
    font-weight: bold;
    cursor: pointer;
    border: 1px solid #ddd !important;
    transform: translate(0, -3px);
    background-color: #f5f5f5; /* Subtle Gray Tones */
}


.permissions-table thead th {
    border: 1px solid #ddd !important;
}

.permissions-table th,
.permissions-table td {
    padding: 10px;
    border: 1px solid #ddd;
    vertical-align: top;
}

.module-name-checkbox {
    margin-right: 5px !important;
}

.dictionaries-list {
    background-color: #ffffff;
    padding: 5px;
    display: inline-block;
}

.dictionary-column {
    background-color: #ffffff;
    float: left;
    width: 380px; 
    padding: 5px;
}

.dictionary-column-round {
    background-color: #ffffff;
    float: left;
    width: 380px; 
    padding: 5px;
    border-radius: 15px;
}

.dictionaries-list-round  {
    background-color: #ffffff;
    padding: 5px;
    display: inline-block;
    border-radius: 15px;
}

.dictionary-type {
    width: 400px; 
    padding: 5px;
}

.dictionary-caption {
    text-align: left;
    font-weight: bold; 
    padding: 5px;
}

.dictionary-item {
    color: #0075E3;
    padding-left: 0px; 
    padding-top: 2px;
    padding-bottom: 2px;
}

.dictionary-constant-caption {
    padding-left: 10px; 
    padding-top: 25px;
    padding-bottom: 2px;
    font-weight: bold;
}

.dictionary-constant-item {
    padding-left: 20px; 
    padding-top: 1px;
    padding-bottom: 1px;
    color: #777676;
}

.dictionary-box {
    background-color: #ffffff !important;
}

.dictionary-box-round {
    background-color: #ffffff !important;
    border-radius: 15px !important;
}

.dictionary-non-link-text {
    color: #777676;
    padding-left: 10px;
}


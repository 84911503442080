.modal-header-custom {
    background-color: #1479BB !important;
    color: white !important;
    padding: 0.7rem 1rem !important;
    margin-left: -8px !important;
}
.modal-header-custom-roles-permission {
    background-color: #1479BB !important;
    color: white !important;
    padding: 0.7rem 1rem !important;
}

.modal-header-custom-crop {
    background-color: #1479BB !important;
    color: white !important;
    padding: 0.7rem 1rem !important;
    border-bottom: 0px !important;
}

.btn-common-height35 {
    height: 35px;
    margin-left: 7px !important;
    margin-right: 7x !important;
}
.btn-common-height28 {
    height: 28px;
    margin-left: 7px !important;
    margin-right: 7x !important;
}

.modal-title-text {
    font-size: 14px !important;
    font-family: 'Lato';
    font-weight: 400;
}

.delete-btn-bg {
    background-color: #EC5F60 !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.modal-header-custom button span {
    color: #f0f0f0;
}

.modal-header-custom button span:hover {
    color: #ffffff;
}

@media only screen and (min-width: 576px) {
    #longWidth.modal-dialog {
        max-width: 100% !important;
        width: 75% !important;
        margin: 1.75rem auto;
    }
}

div[aria-describedby="alert-dialog-description"] {
    max-width: 1000px !important;
    border-radius: 10px;
}

div.MuiDialogContent-root.MuiDialogContent-dividers {
    min-width: 1000px;
    padding: 10px 20px !important;
}

div.MuiDialogContent-root.MuiDialogContent-dividers.small {
    min-width: 600px !important;
    padding: 20px 20px !important;
    max-width: 1000px !important;
    font-size: 14px !important;
}

h2 button.MuiButtonBase-root {
    position: absolute !important;
    color: #ffffff !important;
    animation: none !important;
    padding: 5px !important;
}

h2 button.MuiButtonBase-root:hover {
    position: absolute !important;
    color: #ffffff !important;
    animation: none !important;
    padding: 5px !important;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-x-hidden {
    overflow-x: hidden;
}
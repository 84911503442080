div.dropdown-toggle {
    border: none;
    background: none;
    font-size: 1.3rem;
    line-height: 0;
    outline: none;
    color: #C8C8C8;
  }
  
  div.dropdown-toggle:after {
    display: none;
  }
  
  div.dropdown-menu {
    font-size: .875rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
      transform: translate3d(-86px, 30px, 0px);
      top: 0px !important;
      left: 0px !important;
    -webkit-box-shadow: 0 0 10px 0 rgba(106, 106, 106, 0.16);
            box-shadow: 0 0 10px 0 rgba(106, 106, 106, 0.16);
  }
  
  div.dropdown-menu a {
    margin: 0 12px;
    width: auto !important;
    padding-left: 6px;
    padding-right: 6px;
  }

  .table-margin-bottom{
   
    margin-bottom: 50px !important;
  }
  div.dropdown-menu a.edit {
    color: #2D2F31;
    border-bottom: 1px solid #F4F4F4;
  }
  
  div.dropdown-menu a.delete {
    color: #EC5F60;
  }
  
  
  div.dropdown-menu:before {
    content: "";
    width: 16px;
    height: 16px;
    background: #ffffff;
    position: absolute;
    top: -8px;
    left: 24px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-box-shadow: -4px -3px 7px 0 rgba(106, 106, 106, 0.16);
            box-shadow: -4px -3px 7px 0 rgba(106, 106, 106, 0.16);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-right: none;
    border-bottom: none;
  }
  
  div.dropdown-menu .dropdown-item:active {
    background: none;
  }
  
  .dropdown-item {
    font-size: 12px !important;
  }
  .dropdown-menu.show{
    position: absolute; 
    will-change: transform; 
    transform: translate3d(0px);
    top: 0px; 
    right: 0; 
  }

  .MuiButtonBase-root.MuiIconButton-root.Icon_div{
    background-color: #F5F5F5 !important;
    padding: 9px !important;
    margin-left: 10px;
    border-radius: 4px;
    border:1px solid #E0E0E0
  }
  div.right-cell-item {
    display:flex;
    justify-content: 'space-between';
    width: 30%;
    padding-right: 16px;
    flex-direction: row;
  }
  span.left-item {
    color: #757575;
    width:30%
  }
  span.right-item {
    color: #212121;
    font-weight: 600;
    width:70%;
    text-align:right
  }

  .modal-table-container {
    max-height: 400px; 
    overflow-y: auto;
  }
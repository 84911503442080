.rounded-box {
    display: flex !important;
    justify-content: normal !important;
    padding: 0.5rem !important;
    border-radius: 1rem !important;
    border: 1px solid #dddd;
    background-color: #fbfbfb;
}

.report-card {
    border: 1px black solid;
}

.card-border {
    border: 0.5px #302f2f solid;
}

.debit-card {
    border: 0.5px #00218b solid;
}

.card-radio {
    background-color: #d5d6d8;
    padding: 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.card-radio i {
    padding-right: 5px;
}

.center-text {
    margin: auto;
}
.rounded-box {
    display: flex !important;
    justify-content: normal !important;
    padding: 0.5rem !important;
    border-radius: 1rem !important;
    border: 1px solid #dddd;
    background-color: #fbfbfb;
}

.bg-blue {
    background-color: #30307c !important;
    color: #fbfbfb;
}

.drag-drop-container {
    border: 5px dotted rgba(168, 166, 166, 0.867);
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #dedee7;
    border-radius: 1rem;
    /* text-align: center; */
}

.height-100 {
    height: 40rem;
    overflow-y: auto;
}

.height-50 {
    height: 18rem;
    overflow-y: auto;
}

.description {
    font-size: medium;
    font-weight: bold;
    color: #50505c;
}

.rotated-icon {
    transform: rotate(45deg) !important;
}

.height-full {
    height: 100%;
}
.no-permission-container {
  display: flex;
  justify-content: flex-start;
  align-items:flex-start;
  padding: 20%;
  height: 100vh;
}

.icon {
  margin-right: 20px;
  color: #FF5733; /* Adjust the color to your preference */
}

.message {
  text-align: left;
}

.message h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.message p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333; /* Adjust the color to your preference */
}

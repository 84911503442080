.max-width-3{
	max-width: 3px !important;
}
.margin-top-3px{
	margin-top: 3px !important;
	color: #424242 !important;
	font-weight: 500 !important;
	font-size: 15px !important;
}

.margin-top-3-px{
	margin-top: 3px !important;
	color: #424242 !important;
	font-weight: 500 !important;
}
.width-80-per{
	width:80% !important;
}
.margin-bottom-30px{
	margin-bottom: 30px !important;
}
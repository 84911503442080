.right-content {
    position: fixed;
    height: 100%;
    background: #ffffff;
    right: -30%;
    width: 30%;
    -webkit-transition: .35s;
    transition: .35s;
    z-index: 60;
    top: 0;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 0 3px 15px rgb(0 0 0 / 50%);
  }
  
  .right-content .mCSB_dragger {
    opacity: 0;
  }
  
  .right-content:hover .mCSB_dragger {
    opacity: 1;
  }
  
  .right-content .settings-menu {
    height: 100%;
    padding-bottom: 72px;
  }
  
  .right-content ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }  
  .right-content ul li.head {
      float: right;
    background: #ffffff;
    border-bottom: 1px solid #dddd;
    padding: 13px;
    padding-left: 30px;
  } 
  .right-content.active {
    right: 0;
  }

  .right-content ul li a,
  .right-content ul li.head{
  padding-left: 20px !important;
  color: #1074B9 !important;
  border-bottom: none !important;
}
  
  .close-right-side {
    position: relative !important;
    float: right;
    background: none;
    border: none;
    outline: none;
    color: #000000;
    font-size: 24px;
    position: relative;
    top: -6px;
    right:10px;
  }
 .close-right-side span{
     color: #000;
     font-size: 30px;
 }

 /* new Payment */
 .right-content-w1 {
    position: fixed;
    height: 100%;
    background: #ffffff;
    right: -70%;
    width: 70%;
    -webkit-transition: .35s;
    transition: .35s;
    z-index: 10;
    top: 0;
    bottom: 0;
    box-shadow: 0 3px 15px rgb(0 0 0 / 50%);
  }
  
  .right-content-w1 .mCSB_dragger {
    opacity: 0;
  }
  
  .right-content-w1:hover .mCSB_dragger {
    opacity: 1;
  }
  
  .right-content-w1 .settings-menu {
    height: 100%;
    padding-bottom: 72px;
  }
  
  .right-content-w1 ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .right-content-w1 ul li Link {
    color: #ffff;
    text-decoration: none;
    font-weight: 800;
    font-size: 1rem;
    padding: 9px 18px;
    display: block;
    padding-left: 30px;
  }
  
  .right-content-w1 ul li.head {
    background: #ffffff;
    border-bottom: 1px solid #dddd;
    padding-top: 20px;
    padding-bottom: 20px;
    padding: 18px;
    padding-left: 30px;
  }
  .dropdown-toggle {
    white-space: nowrap;
    border: 0px;
     margin-top: 10px;
    margin-right: 10px;
} 
.font-heading{
  font-weight: 400px !important;
  font-size: 16px !important;
  font-family: 'Lato' !important;
  color: #616161;
}
 
  
  .right-content-w1.active {
    right: 0;
  }

  .right-content-w1 ul li a,
  .right-content-w1 ul li.head{
  padding-left: 20px !important;
  color: #000 !important;
}

.dob-top-margin {
  margin-top: -3px !important;
}

table#overflow_auto{
  table-layout: auto !important;
}

.hover-grow {
  width: 155px;
  transition: width 0.3s ease-in-out;
}

.hover-grow:hover {
  width: 220px;
}
.login-logo {
    text-align: right;
    align-items: right;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 100%;
    vertical-align: middle;
    background-color: #fff;
}

.login-right {
    text-align: left;
    align-items: left;
    background-image: url("../../assets/images/login_bg_right.jpg");
    background-repeat: no-repeat;
    height: 100%;
}

.login-right-container {
    position: relative;
}

.login-right-form {
    /* Center vertically and horizontally */
    position: absolute;
    top: 25%;
    left: 15%;
    text-align: left;
    align-items: flex-start;
    margin: 0 0 0 -25px; /* apply negative top and left margins to truly center the element */
}

.forgot-pwd-form {
    /* Center vertically and horizontally */
    position: absolute;
    top: 25%;
    left: 15%;
    text-align: left;
    align-items: flex-start;
    margin: 0 0 0 0px; /* apply negative top and left margins to truly center the element */
}

.forgot-pwd-form-text {
    /* Center vertically and horizontally */
    position: absolute;
    top: 18%;
    left: 5%;
    text-align: left;
    align-items: flex-start;
}

.login-form-header {
    font-weight: bold;
    font-size: large;
    width: 100%;
    padding-bottom: 15px;
}

.login-sub-header {
    width: 100%;
    padding-bottom: 15px;
}

.login-forgot-pwd {
    width: 100%;
    padding-bottom: 5px;
    font-size: small;
}




/* .searchformfld {
    position: relative;
    margin-bottom: -30px;
    max-height: 32px;
}

.searchformfld label {
    position: relative;
    padding-left: 17px !important;
    top: -26px;
    cursor: text;
    font-size: 14px;
    color: #9a9a9a;
}

.searchformfld input:focus+label, .searchformfld input:not(:placeholder-shown)+label {
    opacity: 1;
    transform: scale(.9) translateY(-155%) translateX(-5px);
    color: #000;
    padding-left: 3px !important;
}

.searchformfld input:focus {
    border: 1px solid #000;
    outline-color: #000;
}

.searchformfld {
    margin: 1px 0px;
}

.searchformfld input {
    width: 100%;
}

.searchformfld label, .searchformfld input {
    transition: all 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.9;
}

.curved-input {
    overflow: hidden;
    border-radius: 25px;
}

#basic-addon2 i {
    color: #9a9a9a;
    font-size: auto;
}

.calendar-input input {
    border: 1px solid #C0C0C0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    height: 32px;
    width: 100%;
    border-radius: 2px;
    font-size: 14px !important;
    position: relative;
    z-index: 1;
    background: none;
}

.calendar-input select {
    border: 1px solid #C0C0C0;
    border-radius: 0.4rem;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    height: 32px;
    width: 100%;
    border-radius: 2px;
    font-size: 14px !important;
    padding-left: 10px !important;
}

.calendar-input .react-datepicker__month-select {
    width: 100%;
}

.form-group-calendar {
    margin-bottom: 1rem;
}

.form-group-calendar input {
    border: 1px solid #C0C0C0;
    -webkit-appearance: none;
    appearance: none;
    box-shadow: none !important;
    height: 32px;
    width: 100%;
    border-radius: 3px;
    font-size: 14px !important;
    position: relative;
    z-index: 1;
    background: none;
    padding-left: 14px !important;
} */

/* input css */

label+.MuiInputBase-root>.MuiOutlinedInput-input {
    margin-top: 10px !important;
    /*margin-bottom: 2px !important;*/
    /* min-width: 200px !important; */
    /*max-width: 300px !important;*/
}

.MuiInputBase-root {
    padding-left: 8px;
    padding-right: 5px;
    width: 'auto'
}

.MuiInputBase-input {
    padding: 0px !important;
}

.MuiFormControl-marginNormal {
    margin-top: 0 !important;
    margin-bottom: 8px;
    padding-left: 8px;
}

.Mui-focused.MuiFormLabel-root {
    
}

.form-group #standard-basic:focus {
   
}

.Mui-focused.MuiInput-root {

}

.input-error.MuiInput-underline::before {
    border-bottom: 0px solid #EC5F60 !important;
}

.MuiMenu-list {
    outline: 0;
    width: 100%;
    min-width: 200px;
    font-family: 'Lato';
}

#date-picker-inline:focus {
    background-color: none !important;
}

.Mui-focusVisible.MuiButtonBase-root {
    background-color: #f0f0f0 !important;
    animation: none !important;
}

.Mui-focused>.MuiSelect-select{
    background-color: grey !important;
}

.MuiInputLabel-shrink {
    transform: translate(0, 1.5px) scale(1) !important;
    /* transform-origin: top !important; */
    /* font-size: 17px !important; */
}

fieldset {
    border-radius: 8 !important;
    border: 1px solid #ced4da !important;
}

.input-error>.MuiTextField-root>.MuiInputBase-root>fieldset {
    border-color: red !important;
}

label+.MuiInputBase-root {
    margin-top: 8px
}

.MuiSelect-root {
    min-width: 100% !important;
    min-height: 24px !important;
}

.input-error .MuiInput-underline::before {
    border-bottom: 1px solid #EC5F60 !important;
}

.MuiFormControl-root {
    width: 100%;
}
.MuiPickersToolbar-toolbar {
    height: 100px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    background-color: #1c3664 !important;
}

.MuiPickersDay-daySelected:hover {
    background-color: #1c3664 !important;
}

.height-22 {
    max-height: 22px !important;
    color: #212529;
}

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl svg.MuiSvgIcon-root.MuiSelect-icon {
    display: none !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd{
    padding-right: 5px !important;
}

.MuiOutlinedInput-root{
    background-color: #fff !important;
}
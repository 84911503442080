.header {
    z-index: 10 !important;
    background: #EEE;
}

.header-img {
    background-color: #ffff;
    border-right: 1.5px solid #E0E0E0;
    border-bottom: 1.5px solid #E0E0E0;
}

button#toggle-button {
    background: none !important;
    border: none;
    outline: none !important;
    -webkit-transition: .3s;
    transition: .3s;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    position: relative;
    cursor: pointer;
    left: 5px;
    top: 6px;
    padding-left: 12px;
    margin-top: -16px !important;
}

.d-none-mobile {
    display: none !important;
}

.right-menu-icon {
    font-size: 1.6rem;
    color: #2E2F30;
    display: block;
    position: relative;
    cursor: pointer;
    top: -3px;
    margin-right: 8px;
    float: right;
}

.margin-top20 {
    margin-top: 20px;
}

.margin-top18 {
    margin-top: 18px;
}

.margin-top25 {
    margin-top: 25px;
}
.margin-top50 {
    margin-top: 50px;
}

.margin-top85{
    margin-top: 85px;
}

.margin-top40 {
    margin-top: 40px;
}

.margin-top85{
    margin-top: 85px;
}
.margin-top23 {
    margin-top: 23px;
}
.margin-top15 {
    margin-top: 15px;
}
.margin-top10 {
    margin-top: 10px;
}
.margin-top1 {
    margin-top: 1px;
}
.margin-top2 {
    margin-top: 2px;
}
.margin-top3 {
    margin-top: 3px;
}
.margin-top4 {
    margin-top: 4px;
}
.margin-top5 {
    margin-top: 5px;
}

.ml_6 label {
    margin-left: -6px !important;
}

.ml_5 label {
    margin-left: -5px !important;
}

.display-flex {
    display: flex;
}

#hover tbody tr:hover {
    background-color: #dddd;
    cursor: pointer;
}
.hover {
    background-color: #fbfbfb;
}

.margin-left-24 {
    margin-left: 24px !important;
}

#display-webkit {
    display: -webkit-inline-box;
    margin-top: 19px;
}

#padding-top {
    padding-top: 12px;
}

.def-practice-row-bg {
    background: rgba(36, 167, 224, 0.10) !important;
    cursor:default !important; 
}

@media only screen and (max-width: 1300px) {
    #headerTitle {
      padding-left: 50px;
    }
  }

  /* Style properties for Report Progress Bar Component */
  .progress-bar-container {
      position: relative;
      width: 60%;
      height: 3px;
      background-color: #a7c5e7;
      border-radius: 3px;
      overflow: hidden;
      display: flex;
      justify-content: center;
  }

  .progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #24A7E0 !important;
      animation: progressBarAnimation 2s linear infinite;
  }

  @keyframes progressBarAnimation {
      0% {
          transform: translateX(-100%);
      }

      100% {
          transform: translateX(100%);
      }
  }

  .fade-enter {
      opacity: 0;
  }

  .fade-enter-active {
      opacity: 1;
      transition: opacity 0.5s ease;
  }

  .fade-exit {
      opacity: 1;
  }

  .fade-exit-active {
      opacity: 0;
      transition: opacity 0.5s ease;
  }

  .pro-style {
border-radius: 50%; 
width: 150px !important;
height: 150px;
margin-left: -32px;

 }

  /***************************************/